.features-list {
  h4 + .label {
    margin-top: 0;
    margin-bottom: 0;
  }

  .switch:has(+ .fill-space h4 + .label) {
    top: 2px;
  }
}
