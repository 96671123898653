#sidemenu {
  .sidemenu-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .sidemenu-header {
    height: 64px;
  }

  .nav {
    margin: 0 13px 32px;
    overflow-y: auto;
    overscroll-behavior: contain;
  }

  .bottom {
    margin: auto 13px 16px;

    .btn-group {
      display: block;

      .btn {
        font-weight: 400;
      }
    }

    .btn-group,
    .nav-link + .nav-link {
      margin-top: 4px;
    }
  }

  hr {
    margin: 13px 0;
    border-top: 1px solid @neutral-150;
  }

  .header-logo {
    display: inline-flex;
    align-items: center;
    padding: 16px 24px;
  }

  .nav-stacked > li {
    & + li {
      margin: 4px 0 0;
    }

    & > a,
    & > button {
      position: relative;
      display: block;
    }
  }

  .nav-link,
  :is(.btn-group, .project-select) > .btn {
    float: none;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-transform: initial;
    letter-spacing: initial;
    color: @neutral-800;
    white-space: nowrap;
    border-radius: 4px;

    &.light {
      font-weight: 400;
    }

    .svg-inline--fa:not(.fa-angle-right) {
      font-size: 16px;
      color: @neutral-400;
      margin-right: 12px;
      flex-shrink: 0;
    }

    & > .fa-angle-right {
      bottom: -1px;
      right: 4px;
      display: none;
      margin-left: auto;
      color: @neutral-400 !important;
    }

    &:is(:hover, :focus) > .fa-angle-right {
      display: inline-flex;
    }

    &:is(:hover, :hover, .active) {
      color: @secondary-600;
      background-color: @neutral-100;
      text-decoration: none;

      .svg-inline--fa {
        color: @secondary-500-base;
      }
    }

    &.active {
      background-color: rgba(224, 240, 255, 0.6);
    }

    + .dropdown-menu {
      left: calc(100% + 1px);
      bottom: -8px;
      width: 320px;
      padding: 16px;
    }
  }
}

.btn-header {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  svg {
    font-size: 13px;
  }

  &:hover,
  &:focus {
    background-color: @neutral-100;
    color: @secondary-500-base;
  }
}
