#data-sample-table {
  position: relative;
  padding-top: 7px;

  &.full-screen {
    overflow: hidden;
  }

  &.full-screen .table-responsive.sticky-header {
    max-height: calc(100vh - 220px);
  }

  .table-responsive.sticky-header {
    overflow-y: auto;
    max-height: calc(100vh - 510px);

    @media all and (max-height: 1000px) {
      max-height: calc(100vh - 425px);
    }

    th,
    .filters-row td {
      position: sticky;
      z-index: 10;
    }

    th {
      height: 70px;
      top: 0;
    }

    .filters-row td {
      height: 50px;
      top: 70px;
    }
  }

  table {
    padding: 0 8px 8px;
    border-spacing: 0;

    thead > tr > th,
    tbody > tr.filters-row > td,
    tbody > tr:hover:not(.no-hover) > td {
      background-image: none;
      background-color: @neutral-50;

      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }

    thead > tr {
      box-shadow: none;

      & > th {
        padding: 15px;

        &:first-child {
          padding-left: 20px;
        }
      }
    }

    tbody > tr {
      & > td {
        padding: 15px;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      &.filters-row > td {
        padding: 10px 5px;
        border-top: 4px solid @white;
        border-bottom: 4px solid @white;

        &:first-child {
          padding-left: 10px;
        }

        &:last-child {
          padding-right: 10px;
        }
      }

      & > td.active-filter-column {
        color: @secondary-500-base;
        background-color: @secondary-100 !important;
        border-left: 5px solid @white;
        border-right: 5px solid @white;
        font-weight: 500;

        &:first-child {
          border-left-width: 10px;
        }

        &:last-child {
          border-right-width: 10px;
        }
      }

      &:hover > td.active-filter-column {
        background-color: @secondary-100 !important;
        border-left-color: @neutral-50;
        border-right-color: @neutral-50;
      }
    }

    .form-group {
      position: relative;
      max-width: 300px;

      .form-control {
        min-width: 150px;
        padding: 12px 30px 12px 12px;
        background-color: @white;
        border-radius: 2px;

        &::-webkit-input-placeholder {
          color: @neutral-200;
        }

        &::-moz-placeholder {
          color: @neutral-200;
          opacity: 1;
        }

        &.active {
          font-weight: 500;
          background-color: @secondary-500-base;
          color: @white;
        }

        &:not(:focus-within) + button.save-filter:not(:focus):not(:hover) {
          display: none;
        }
      }

      button {
        position: absolute;
        top: 6px;
        right: 6px;
        padding: 5px;

        &:not(.save-filter) {
          svg path {
            fill: @white;
          }
        }
      }
    }
  }
}

@media all and (max-height: 1000px) {
  .main-container:has(#data-sample-table .table-responsive.sticky-header) {
    padding-bottom: 2em;
  }
}
