.markdown {
  h1:first-child,
  h2:first-child {
    margin-top: 10px;
  }

  table {
    background-color: @white;
    width: 100%;
    max-width: 100%;
    margin-bottom: @box-margin;
  }

  caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: @neutral-400;
    text-align: left;
  }

  th {
    text-align: left;
  }

  table > thead > tr > th,
  table > tbody > tr > th,
  table > tfoot > tr > th,
  table > thead > tr > td,
  table > tbody > tr > td,
  table > tfoot > tr > td {
    padding: @table-cell-padding;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid @neutral-100;
  }
  table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid @neutral-100;
  }
  table > caption + thead > tr:first-child > th,
  table > colgroup + thead > tr:first-child > th,
  table > thead:first-child > tr:first-child > th,
  table > caption + thead > tr:first-child > td,
  table > colgroup + thead > tr:first-child > td,
  table > thead:first-child > tr:first-child > td {
    border-top: 0;
  }
  table > tbody + tbody {
    border-top: 2px solid @neutral-100;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
