.dragable-option {
  background-color: @neutral-50;
  min-height: 40px;
  padding: 8px 14px;
  border-radius: @border-radius;
  border: @border;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.active {
    background-color: @white;
  }

  &.active:hover,
  &.sortable-chosen {
    .fa-grip-dots-vertical.dragable {
      color: @neutral-400;
    }
  }

  &.sortable-chosen {
    border-color: @secondary-500-base !important;
    box-shadow: @outline-shadow;
  }

  .common-checkbox {
    margin-left: 12px;

    [type='checkbox'] {
      position: relative;
      top: 1px;
    }
  }

  .fa-grip-dots-vertical {
    color: @neutral-200;
  }

  .btn-link-inline {
    opacity: 0;
    padding: 1px 3px;
    transition: opacity 200ms ease-in;
  }

  &:hover,
  &:focus-within {
    .btn-link-inline {
      opacity: 1;
    }
  }
}

#gantt-container {
  position: relative;

  .chart {
    shape-rendering: crispEdges;

    .axis {
      font-family: @font-family-sans-serif;
      font-size: 10px;
      color: @neutral-500-base;
    }

    .task {
      fill: @secondary-500-base;
    }

    .task-label {
      fill: @white;
    }

    .preview-task {
      fill: @neutral-200;
    }

    .brush {
      .overlay {
        fill: rgba(0, 0, 0, 0.05);
      }

      .selection {
        stroke: @secondary-300;
        fill: @secondary-300;
        fill-opacity: 0.25;
      }
    }
  }

  .tooltip {
    position: absolute;
    pointer-events: none;
    background: @neutral-700;
    min-width: 180px;
    padding: 5px 6px;
    border-radius: @border-radius;
    text-align: left;

    h3 {
      font-size: 13px;
      color: @white;
      margin: 0;
    }

    p {
      font-size: 12px;
      color: @white;
      margin: 0;
    }
  }
}
