.sortable-fallback {
  opacity: 1 !important;

  &.row-sort {
    background-color: @white;
  }
}

body:has(.sortable-drag) *:hover {
  cursor: grabbing !important;
}
