.template-preview-box {
  padding: 4px 24px 24px;

  .box-header,
  .box-panel-content {
    padding: 0;
  }

  .box-panel-content {
    display: flex;
    flex-flow: wrap;
    height: 100%;
    justify-content: space-between;

    h3 {
      line-height: 20px;
    }

    & > p {
      width: 100%;
    }

    & > .btn {
      align-self: end;
    }
  }
}

.template-detail {
  .component-box {
    border-radius: @border-radius-large;

    &:hover {
      background-color: @neutral-50;
    }
  }
}

.template-instance-configurator {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 835px;
  margin: auto;
  margin-top: 60px;

  & > .box-container {
    position: relative;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
    margin-top: 5em;
    gap: 1em;

    &:has(.instance-step-group-requirements) {
      margin-top: 6.1em;
    }

    &.with-border {
      margin-top: 5em;
      padding: 1em;
      border: 2px solid rgba(200, 202, 217, 0.5);
      border-radius: 12px;

      &:has(.instance-step-group-requirements) {
        margin-top: 5.5em;
      }
    }

    & > .box {
      min-width: 394px;
      margin-bottom: 0;
      flex-grow: 0;

      .box-header-inner {
        flex-grow: 1;

        & > .flex-container {
          height: 28px;

          .box-title {
            margin-right: auto;
          }
        }
      }
    }

    & > .instance-step-group-requirements {
      position: absolute;
      top: -23px;
      background-color: @neutral-50;
    }

    &.with-border > .instance-step-group-requirements {
      top: -13px;
    }

    & > .instance-connector-icon {
      display: flex;
      position: absolute;
      height: 50px;
      width: 2px;
      top: auto;
      bottom: -62px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      background-color: @neutral-400;

      & > svg:first-of-type {
        margin-top: -15px;
      }

      & > svg:last-of-type {
        margin-bottom: -7px;
      }
    }
  }
}
