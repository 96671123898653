.full-screen-vault-modal {
  .modal-dialog {
    overflow: hidden;
  }

  .modal-header {
    padding-top: 16px !important;

    .fa-stack,
    .fa-stack-2x {
      width: 40px;
    }
  }

  .row-buttons > .btn {
    width: 40px;
    height: 40px;
  }

  .full-screen-table {
    overflow-y: auto;

    &.active {
      max-height: calc(100vh - 292px);
    }

    .is-sticky {
      top: 4px;
    }
  }

  .table {
    border-spacing: 0;
    padding-bottom: 0;

    & > tbody > tr {
      > td {
        height: 52px;
        padding-top: 6px;
        padding-bottom: 6px;

        &:first-child .common-checkbox.disabled {
          opacity: 0;
          pointer-events: none;
        }

        .row-dropdown .dropdown-toggle {
          height: 40px;
        }
      }

      &.form-row:first-child > td {
        padding-top: 16px;
      }

      &.form-row:last-child > td {
        padding-bottom: 16px;
      }

      &.folder-row > td {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    &.oauth-variables-table > tbody > tr > td {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .variable-row.in-folder td:first-child {
      padding-left: 46px;
    }

    .row-action {
      padding: 8px 20px;
    }

    span.with-expand {
      padding-left: 10px;

      button {
        left: -6px;
        padding: 0 !important;
      }
    }
  }
}
