.Select:not(.select-region) {
  &.react-select--is-disabled {
    pointer-events: auto;

    .react-select__indicator {
      pointer-events: none;
    }

    .react-select__control:hover,
    .react-select__control:focus {
      border-color: transparent;
    }
  }

  &.is-white .react-select__control {
    background-color: @white;
    border: @border;
  }

  &.with-search-icon .react-select__control {
    position: relative;

    .react-select__value-container {
      padding-left: 44px !important;
    }

    .fa-magnifying-glass {
      position: absolute;
      left: 18px;
      top: 0;
      bottom: 0;
      margin: auto 0;
    }
  }

  .react-select__control {
    min-height: 40px;
    background-color: @input-bg;
    border: 1px solid @transparent;

    &.react-select__control--is-disabled {
      background-color: @neutral-100;
      cursor: not-allowed;
    }

    .react-select__single-value {
      color: @neutral-800;
    }
  }

  .react-select__loading-indicator {
    color: @neutral-600;
  }

  .react-select__value-container {
    padding-left: 12px !important;

    .react-select__multi-value {
      background: @secondary-500-base;

      code {
        font-family: @font-family-monospace;
        padding: 0;
        font-size: 12px;
        border-radius: 0;
        background: @transparent;
        color: @white;
      }

      .react-select__multi-value__label {
        color: @white;
        font-size: 12px;
        font-weight: 500;

        &:has(> .truncated) {
          white-space: initial;
        }
      }

      .react-select__multi-value__remove {
        color: @white;

        &:hover,
        &:focus {
          background: @secondary-700;
          color: @white;
        }
      }
    }
  }

  .select-sortable {
    flex: 1 1 0%;
    max-height: 210px;
    overflow-y: auto;

    & > .react-select__value-container .sortable-chosen {
      & > .react-select__multi-value {
        background: @secondary-600;

        & > .react-select__multi-value__remove {
          pointer-events: none;
        }
      }

      & ~ .react-select__input-container {
        display: none;
      }
    }
  }

  .react-select__placeholder {
    color: @neutral-400;
  }
}

.Select:not(.select-region) .react-select__menu,
.react-select__menu-portal .react-select__menu {
  margin-top: 6px;
  z-index: 500;

  .react-select__menu-list {
    border-radius: @border-radius;
    padding: 0;
  }

  .react-select__option {
    width: 100%;
    cursor: pointer;
    padding-left: 16px;

    &.flex-container {
      display: flex !important;
    }

    &.option-danger {
      background-color: @error-500-base;
    }

    &.react-select__option--is-disabled {
      color: @neutral-400 !important;
    }

    &.react-select__option:hover,
    &.react-select__option--is-selected,
    &.react-select__option--is-focused {
      color: inherit;
      background-color: @input-bg;
    }

    .option-comment {
      padding: 4px 4px 0 4px;
    }

    .common-checkbox {
      margin: 5px 10px 5px 5px;
    }

    & + hr {
      width: auto;
      margin: 8px;
    }
  }

  .react-select__menu-notice {
    text-align: left;
    padding-left: 16px;
  }

  .react-select__group-heading {
    color: @neutral-800;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 4px 12px 4px 16px;
  }
}

.Select.table-input-mapping-multiselect .select-sortable {
  width: calc(100% - 75px);

  .react-select__input-container {
    max-width: calc(100% - 170px);
    overflow: hidden;
  }
}
