#jobs-advanced-search {
  position: relative;

  .filters-container {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(4, 1fr);
  }

  .form-control,
  .Select .react-select__control {
    background-color: @white;
    border: @border;
  }

  .Select.react-select--is-disabled {
    opacity: 0.4;
  }

  .time-range-container {
    position: relative;
  }

  .controls {
    height: 40px;
    position: absolute;
    top: -70px;
    right: 0;

    .switch-wrapper {
      height: 36px;
      min-width: 100px;
    }

    .btn-link-inline {
      line-height: 36px;
      min-width: 103px;
    }
  }

  .slide-down {
    &-exit-done,
    &.closed:not(.slide-down-exit) {
      opacity: 0;
      height: 0px;
      overflow: hidden;
    }

    &-enter {
      opacity: 0;
      height: 0px;

      &-active {
        opacity: 1;
        height: 52px;
        transition:
          opacity 300ms,
          height 300ms;
      }
    }

    &-exit {
      height: 52px;

      &-active {
        opacity: 0;
        height: 0px;
        transition:
          opacity 300ms,
          height 300ms;
      }
    }
  }
}
