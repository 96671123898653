#select-timezone-dropdown + .dropdown-menu {
  min-width: 438px;
  right: -3px;
  border-radius: 4px;
  padding: 0;

  .react-select__control {
    margin: 12px 16px;
  }

  .react-select__menu {
    position: initial;
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .react-select__menu-list {
    max-height: 272px;
    border-radius: 0;
  }
}
