.fast-fade {
  opacity: 0;
  transition: opacity 50ms linear;

  &.in {
    opacity: 1;
  }
}

.fade {
  &-enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity 300ms linear;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity 300ms linear;
    }
  }
}
