.switch-wrapper {
  display: inline;
  padding: 7px 12px;

  &.in-navigation {
    position: relative;
    top: -1px;

    > .switch-wrapper-label {
      top: 1px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .switch-wrapper-label {
    position: relative;
    top: 1px;
    font-size: 13px;
    margin-left: 10px;
  }
}

&:hover,
&:focus {
  & > .switch:not(.switch-disabled) {
    outline: none;
    border-color: @neutral-300;
    background-color: @neutral-300;

    &.switch-checked {
      border-color: @primary-600;
      background-color: @primary-600;
    }
  }
}

.switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  height: 16px;
  line-height: 16px;
  padding: 0;
  vertical-align: middle;
  text-align: center;
  border-radius: 16px 16px;
  border: @border;
  background-color: @neutral-200;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);

  &::after {
    position: absolute;
    width: 12px;
    height: 12px;
    left: 1px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: @white;
    content: ' ';
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  }

  &.wider {
    width: 32px;
  }

  &.switch-checked {
    border: 1px solid @primary-500-base;
    background-color: @primary-500-base;

    &::after {
      left: auto;
      right: 1px;
    }
  }

  &.switch-disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &::after {
      cursor: not-allowed;
    }
  }

  .switch-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }

  .switch-inner {
    display: none;
  }
}
