.box {
  &.box-genericVariablesUI,
  &.box-secrets {
    margin-bottom: @box-margin;
    margin-top: @box-margin;

    .box-content {
      .variable-static,
      .variable-editing {
        border-radius: @border-radius;
        margin-bottom: 10px;
        border: @border;

        .variable-name,
        .variable-value {
          font-family: @font-family-monospace;
        }
      }
      .variable-static {
        padding: 13px 8px 13px 24px;
        .variable-name {
          margin-right: 1em;
          color: @accent-purple-500-base;
          font-weight: bold;
        }
        .variable-value {
          color: @neutral-400;
          margin-right: auto;
          &:before {
            content: '(';
            color: @neutral-400;
          }
          &:after {
            content: ')';
            color: @neutral-400;
          }
        }
        .variable-actions {
          margin-left: 1em;

          & > .btn {
            padding: 7px 10px;

            & > .svg-inline--fa {
              font-size: 16px;
            }
          }
        }

        @media all and (min-width: @screen-md-min) {
          .variable-actions {
            opacity: 0;
            transition: opacity 200ms ease-in-out;
          }

          &:hover .variable-actions,
          &:focus-within .variable-actions {
            opacity: 1;
          }
        }

        &:hover {
          background-color: @neutral-50;
        }
      }
      .variable-editing {
        form {
          display: flex;
          flex-direction: column;
          position: relative;
          border: 1px solid @transparent;
          border-radius: @border-radius;
          padding: 12px 12px 12px 23px;

          @media all and (min-width: @screen-md-min) {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
          }

          &:focus-within {
            border: 1px solid @secondary-500-base;
            box-shadow: @outline-shadow;
            border-radius: @border-radius;
          }

          > .svg-inline--fa {
            margin-top: -2px;
          }

          .variable-form-inner {
            display: flex;
            align-items: center;

            @media all and (min-width: @screen-md-min) {
              justify-content: space-between;
            }
          }

          .variable-name {
            margin-left: -2px;
            margin-right: 0.5em;
          }

          .variable-name,
          .variable-value {
            display: inline-block;
            input {
              border: none;
              border-bottom: 2px solid @transparent;
              outline: none;
              &:focus,
              &:hover {
                border-bottom: 2px solid @secondary-500-base;
              }
              &.has-error,
              &.has-error:focus,
              &.has-error:hover {
                border-bottom: 2px solid @error-500-base;
              }
            }
          }
          .variable-controls {
            display: flex;
            gap: 12px;
            margin-top: 18px;

            @media all and (min-width: @screen-md-min) {
              margin-top: 0;
              margin-left: 14px;
            }
          }
        }
      }
    }
  }
}

.panel .variable-override {
  .variable-name {
    font-family: @font-family-monospace;
    font-weight: bold;
    color: @accent-purple-500-base;
    padding: 10px 18px;
  }

  .variable-value {
    position: relative;
    padding: 10px 16px;

    input {
      display: inline-block;
      width: auto;
      padding-right: 34px;

      ~ button {
        position: absolute;
        right: 22px;
      }
    }
  }
}
