.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.inline-flex {
    display: inline-flex;
  }

  &.align-top {
    align-items: flex-start;
  }

  &.align-bottom {
    align-items: flex-end;
  }

  &.stretch {
    align-items: stretch;
  }

  &.flex-start {
    justify-content: flex-start;
  }

  &.flex-end {
    justify-content: flex-end;
  }

  &.justify-center {
    justify-content: center;
  }

  &.flex-reverse {
    flex-direction: row-reverse;
  }

  &.flex-column {
    flex-direction: column;
  }

  &.limit-3 {
    justify-content: flex-start;
    flex-wrap: wrap;

    & > * {
      flex: 1 0 calc(100% / 3);
      max-width: calc(100% / 3);

      @media all and (max-width: @screen-md-max) {
        flex-basis: 50%;
        max-width: 50%;
      }

      @media all and (max-width: @screen-sm-max) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    &.gap-16 > * {
      flex: 1 0 calc((100% / 3) - 11px);
      max-width: calc((100% / 3) - 11px);

      @media all and (max-width: @screen-md-max) {
        flex-basis: calc(50% - 8px);
        max-width: calc(50% - 8px);
      }

      @media all and (max-width: @screen-sm-max) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  .align-start {
    align-self: start;
  }

  .no-shrink {
    flex-shrink: 0;
  }
}

.gap-16 {
  gap: 16px !important;
}

.gap-24 {
  gap: 24px !important;
}

.fill-space {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  display: flex;
  flex-wrap: nowrap;
}
