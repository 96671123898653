body {
  &.full-screen-modal-open {
    overflow: hidden;
  }

  &:has(.full-screen-editor.half-screen) {
    padding-bottom: calc(50vh - 4em);
  }
}

.full-screen-modal {
  z-index: 1039;
  text-align: left;

  .modal-dialog {
    background: @neutral-50;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 0;
    padding: 0;
  }

  &.with-scroll .modal-dialog {
    overflow-y: scroll;
  }

  .modal-header .btn-toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    white-space: nowrap;
    margin-left: 24px;

    .svg-inline--fa {
      max-width: 16px;
    }

    .btn-toolbar,
    .btn-separator + button {
      margin-left: 0;
    }
  }

  .modal-content {
    background: @neutral-50;
    padding: 32px;
    height: auto;
    min-height: 100%;
    box-shadow: none !important;
    border-radius: 0;

    .modal-header::before,
    .modal-header::after,
    .btn-toolbar::before,
    .btn-toolbar::after {
      display: none;
    }

    & > div:focus-visible {
      outline: none;
    }
  }

  &.full-screen-generic-overview,
  &.full-screen-vault-modal,
  &.full-screen-data-sample {
    .modal-header {
      padding: 4px 12px 8px 12px;
      border-bottom: none;

      h4 {
        font-size: 24px;
        line-height: 32px;
        margin: 0;

        .svg-inline--fa:not(.fa-stack-1x) {
          font-size: 26px;
        }
      }
    }

    .modal-body .tab-content {
      border-top: @border;
      padding-top: 28px;
    }
  }

  &.full-screen-editor {
    &:not(.full-screen-data-sample) {
      .modal-content {
        background: @white;

        &:has(> .modal-header + .modal-body > .react-codemirror2:last-child) {
          display: flex;
          flex-direction: column;

          & > .modal-body {
            flex: 1;
          }
        }

        .modal-header {
          border-bottom: none;
          padding-top: 4px;
          padding-left: 0;
        }

        .modal-title {
          flex-grow: 1;
          max-width: 70%;
          overflow: hidden;
          color: @neutral-900;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
        }

        .modal-body {
          padding: 0;
          position: relative;
          height: calc(100vh - 128px);

          #raw-tabs > .nav-tabs {
            border-bottom: @border;

            + .tab-content {
              position: relative;
              height: calc(100vh - 152px);

              .react-codemirror2 .CodeMirror {
                top: 24px;
              }
            }
          }

          & > .form-control + .react-codemirror2 .CodeMirror {
            top: 48px;
          }

          .react-codemirror2 {
            height: 100%;
            text-align: left;

            .CodeMirror {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 9;
              height: auto;
            }

            & + .help-block {
              margin: 7px 0 0;
            }
          }
        }
      }

      &.half-screen {
        top: 50%;
        left: 270px;
        right: 20px;
        bottom: 20px;
        z-index: 1020;
        border-radius: @border-radius-large;
        box-shadow: 0px 10px 20px 20px rgba(125, 125, 125, 0.25);

        .modal-content .modal-body {
          height: calc(50vh - 148px);
        }

        &.slide-up {
          &-appear {
            transform: translateY(100%);
          }

          &-appear-active {
            transform: translateY(0px);
            transition: transform 300ms ease-out;
          }

          &-exit {
            transform: translateY(0px);
          }

          &-exit-active {
            transform: translateY(100%);
            transition: transform 300ms ease-in;
          }
        }
      }

      &.with-warning {
        .modal-content > .modal-header {
          padding-bottom: 0px;
        }

        &:not(.half-screen) {
          .modal-content > .modal-body {
            height: calc(100vh - 194px);
          }
        }

        &.half-screen {
          .modal-content > .modal-body {
            height: calc(50vh - 214px);
          }
        }
      }
    }

    &.without-tabs .modal-content .modal-body {
      height: calc(100vh - 112px);
    }
  }

  .btn-group-success {
    margin-left: 8px;
  }
}

.extra-large-modal {
  .modal-dialog {
    width: 1200px;
    max-width: 90%;
    padding: 0;
  }
}

.scrollable-modal .modal-dialog {
  height: 640px;
  max-height: calc(90vh - 75px);
  overflow-y: auto;

  .modal-content,
  .modal-content > form,
  .modal-content > div,
  .modal-content .modal-body {
    height: 100%;
  }
}
