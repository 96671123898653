.info-row {
  display: flex;
  margin-bottom: @box-margin;
  flex-direction: column;
  .overflow-break-anywhere();

  @media all and (min-width: @screen-sm-min) {
    flex-direction: row;
  }

  .info-row-section {
    flex: 1;
    border-left: none;
    padding: @padding-base*6;

    @media all and (min-width: @screen-sm-min) {
      border-left: @border;
    }

    &.more-space {
      flex-grow: 1.5;
    }

    &:first-child {
      border-left: none;
    }

    .first-line {
      align-items: flex-start;
      font-size: 16px;
      color: @neutral-800;
      margin: 0 0 14px 0;
      line-height: 24px;

      &.f-14 {
        line-height: 16px;
      }

      .fa-circle-info,
      .fa-circle-question {
        color: @neutral-400;
        font-size: 12px;
        position: relative;
        top: -1px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      > :first-child:not(:last-child) {
        margin-right: 1.5em;
        flex-shrink: 0;
      }

      :last-child {
        text-align: right;
        .overflow-break-anywhere();
      }
    }

    .last-line {
      color: @neutral-400;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      &.base-size {
        font-size: @font-size-base;
      }
    }
  }
}
