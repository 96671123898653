.inline-edit-input {
  position: relative;
  display: inline-flex;
  align-items: baseline;

  .form-control {
    max-width: 400px;
    height: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    background: transparent;
    padding: 0px 28px 0px 0px;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    border-bottom: 2px solid @neutral-400;
  }

  &:hover .form-control,
  & .form-control:hover,
  & .form-control:focus {
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid @secondary-500-base;
  }

  .btn[type='submit'],
  .btn[type='reset'] {
    position: relative;
    left: -22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 20px;
    height: 20px;
    padding: 0;

    .svg-inline--fa {
      width: 12px;
      height: 12px;
    }
  }

  .btn[type='reset'] {
    color: @neutral-400;
    background: transparent;
    border: none;
    margin-left: 8px;

    &:hover,
    &:focus,
    &:active {
      color: @neutral-600;
      background: transparent;
      border: none;
    }
  }
}

.inline-edit-input-edit {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .fa-pen {
    color: #fff;
    background: @neutral-400;
    width: 12px;
    height: 12px;
    padding: 4px;
    border-radius: 2px;
  }

  &:hover .fa-pen {
    background: @neutral-600;
  }
}
