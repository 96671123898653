@cmdk-gray: rgba(255, 255, 255, 0.7);

[cmdk-dialog] {
  position: fixed;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);

  [cmdk-root] {
    width: 100%;
    max-width: 800px;
    margin: 75px auto 30px;
    background: @white;
    border-radius: @border-radius;
    overflow: hidden;
    box-shadow: 0 10px 20px #6487992e;
  }

  [cmdk-input] {
    border: none;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 20px 200px 20px 60px;
    outline: none;
    background: @neutral-800;
    color: @white;

    &::placeholder {
      color: @cmdk-gray;
    }
  }

  #global-search-tabs {
    .nav-tabs {
      background: @neutral-800;
      padding: 0 12px;
      float: none;

      & > li {
        margin: 0 12px;

        a {
          color: @cmdk-gray;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 8px 0px 16px;

          &:hover,
          &:focus {
            color: @white;
            border-color: @white;
          }
        }

        &.active a {
          color: @white;
          border-color: @white;
        }
      }

      &:has(+ .tab-content > #global-search-tabs-pane-all [cmdk-empty]) {
        display: none;
      }
    }
  }

  [cmdk-group] {
    position: relative;
    padding-top: 10px;
  }

  [cmdk-group-heading] {
    color: @neutral-800;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 24px;

    & + [cmdk-group-items] > .btn-link-inline {
      position: absolute;
      top: 17px;
      right: 24px;
    }
  }

  [cmdk-item] {
    cursor: pointer;
    height: 44px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    margin: 0 12px;
    border-radius: @border-radius;
    color: @neutral-800;
    white-space: pre-wrap;

    &[aria-selected='true'] {
      background-color: @neutral-100;
    }

    .type {
      margin-left: 4px;
    }
  }

  [cmdk-list] {
    max-height: 480px;
    height: var(--cmdk-list-height);
    scroll-padding-block-start: 10px;
    scroll-padding-block-end: 10px;
    overflow-y: auto;
  }

  [cmdk-list-sizer] {
    padding: 5px 0 15px 0;

    & > [cmdk-item]:first-child {
      margin-top: 10px;
    }
  }

  [cmdk-empty] {
    font-size: 14px;
    line-height: 20px;
    color: @neutral-400;
    text-align: center;
    padding: 30px 0 20px 0;
  }

  .input-with-addons {
    position: relative;

    .fa-magnifying-glass {
      position: absolute;
      top: 24px;
      left: 24px;
      color: @cmdk-gray;
    }

    .btn {
      position: absolute;
      top: 20px;
      height: 24px;
      right: 156px;
      color: @cmdk-gray;
      border-right: 1px solid @neutral-500-base;
      border-radius: 0;
      padding: 0px 8px;
      margin-right: 8px;

      &:hover,
      &:focus {
        color: @white;
        outline: none;
        border-color: @neutral-500-base;
      }

      svg {
        bottom: 0;
      }
    }

    .addon {
      position: absolute;
      top: 0;
      right: 0;
      line-height: 24px;
      padding: 20px 24px 20px 0;
      color: @cmdk-gray;

      .label {
        color: @neutral-600;
        background: @white;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        padding: 2px 4px;
        margin: 0 8px;
        border-radius: 2px;
        letter-spacing: @letter-spacing-wider;
      }
    }
  }
}
