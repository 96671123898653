.label {
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 2px 6px;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: underline @transparent;
  letter-spacing: @letter-spacing-wider;

  + .label {
    margin-left: 8px;
  }

  &.color-inverse {
    &.label-success {
      color: @primary-500-base;
      background: @primary-100;
      border: 2px solid @white;
    }
  }
}

.label-black {
  background: @neutral-700;
  color: @white;
}

.label-primary,
.label-success,
.label-success-muted,
.label-info,
.label-danger {
  a {
    color: @white;
  }
}

.label-primary {
  text-decoration-color: @secondary-500-base;
}

.label-success {
  text-decoration-color: @primary-500-base;
}

.label-success-muted {
  text-decoration-color: @primary-600;
  background-color: @primary-600;
}

.label-info {
  text-decoration-color: @accent-purple-500-base;
}

.label-danger {
  text-decoration-color: @error-500-base;
}

.label-teal {
  background-color: @accent-teal-600;
  text-decoration-color: @accent-teal-600;
}

.label-orange {
  color: @warning-500-base;
  background-color: rgba(255, 233, 165, 0.5);
  text-transform: uppercase;
}

.label-orange-muted {
  color: @neutral-800;
  background-color: @warning-200;
}

.label-secondary-muted {
  color: @neutral-800;
  background-color: @secondary-100;
}

.label-purple {
  color: @accent-purple-500-base;
  background-color: @accent-purple-100;
  text-transform: uppercase;

  &.reverse {
    color: white;
    background-color: @accent-purple-500-base;
  }
}

.label-cyan {
  background-color: @accent-cyan-500-base;
  text-decoration-color: @accent-cyan-500-base;
}

.label-rounded {
  border-radius: 10px;
}

.sandbox-label {
  display: inline-block;
  min-width: 68px;
  padding-top: 6px;
  padding-bottom: 6px;
  letter-spacing: @letter-spacing-wider;
  text-transform: uppercase;
}

.label-backend {
  margin-left: 5px;

  &-wrap {
    display: inline-block;
    white-space: nowrap;
  }
}

.bigger-label {
  display: inline-block;
  background: @neutral-400;
  color: @white;
  padding: 5px 8px;
  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
  margin-right: 10px;
  letter-spacing: @letter-spacing-wider;
  border-radius: @border-radius;

  &.clickable {
    .fade-transition(all, 120ms);

    &:hover,
    &:focus {
      color: @white;
      background: @secondary-500-base;
      text-decoration: none;
    }
  }
}

.react-select__option.options-group-label {
  position: relative;
  display: inline-flex;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    left: 0;
    right: 0;
    border: 1px dashed @neutral-50;
  }

  .label {
    position: relative;
    margin-left: -6px;
    color: @neutral-400;
    background-color: @neutral-50;
    text-transform: uppercase;
    border-radius: 10px;
    text-align: left;
    font-weight: 500;
    white-space: inherit;
  }
}

.stage-label {
  display: inline-block;
  min-width: 40px;
  margin-right: 10px;

  &.label-rounded {
    min-width: inherit;
    margin-left: 10px;
    margin-right: 0px;
  }

  & + .dev-branch-label {
    margin-left: -5px;
  }
}

.dev-branch-label {
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  background-color: @warning-500-base;
  text-decoration-color: @warning-500-base;

  & > .svg-inline--fa {
    font-size: 12px;
  }
}

.labels-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  & > .label {
    display: inline-flex;
    margin: 2px 4px 2px 0px;
    white-space: break-spaces;
    text-align: left;
    .overflow-break-anywhere();
  }
}

.backend-size-label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: capitalize;

  &.pull-right {
    justify-content: flex-end;
  }

  &.emphasized {
    color: @secondary-500-base;
    font-size: 12px;
    text-transform: uppercase;

    span {
      margin-left: auto;
    }
  }

  svg:not(:last-of-type) {
    margin-right: 5px;
  }

  &:not(.pull-right) > svg:last-of-type {
    margin-right: 10px;
  }
}

td,
.td {
  & > .backend-size-label.emphasized {
    font-size: 16px;
  }

  &.pl-0 > .backend-size-label {
    margin-left: 16px;
  }

  .backend-size-label {
    padding-bottom: 4px;
  }
}

.default-backend-size-placeholder {
  text-transform: none;
  font-size: 13px;
  letter-spacing: initial;
  text-decoration: none;

  .svg-inline--fa {
    margin-left: 10px;
    bottom: 0;
  }
}

.btn .label {
  top: 0px;
}

.in-title {
  position: relative;
  top: 3px;
}
