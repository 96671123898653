.json-editor {
  div[data-schemapath='root'] > h3 {
    display: none !important;
  }

  .well.well-sm {
    margin: 0;
    padding: 0;
    border: none;

    .well.well-sm {
      margin: 1em 0px;
      padding: 16px !important;
      border: @border;
      border-radius: @border-radius-large;
      box-shadow: none;
    }

    .row > div {
      padding: 0 15px;
    }

    .je-object__container .je-header {
      margin-bottom: 0;
    }

    & > div:has(> .je-object__container:not([style*='display: none'])) + .btn-group {
      margin-top: 1em;
    }
  }

  .form-group {
    a {
      display: block;
      margin: 10px 0 5px 0;
      font-size: 13px;
    }

    .help-block a,
    .control-label a {
      display: initial;
    }
  }

  .table {
    min-width: 100%;
    margin-bottom: 15px;
    border-collapse: collapse;
  }

  .Select .react-select__control--is-focused .react-select__input-container {
    grid-template-columns: 0 auto;
  }
}
