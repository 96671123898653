.box {
  &.transparent-header {
    box-shadow: none;
    background: @transparent;
  }

  &.with-blocks {
    .block-actions {
      margin-right: 8px;
      .btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 7px 8px 5px 8px;
      }
    }
  }

  .code-block {
    position: relative;
    min-height: 60px;
    max-width: 100%;
    color: @neutral-800;
    background: @white;
    border: @border;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 10px 8px 10px 24px;
    margin-bottom: 10px;
    border-radius: @border-radius;
    text-transform: none;

    &:hover,
    &:focus {
      box-shadow: @box-shadow;
    }

    .code-name {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      + .btn-link-muted {
        margin-left: 6px;
        padding: 1px 3px 3px 3px;

        .svg-inline--fa {
          font-size: 12px;
        }
      }
    }

    &.is-last {
      color: @neutral-400;
    }

    & > .fa-code {
      font-size: @font-size-base;
    }

    & > .circle-icon.smaller-icon {
      margin-left: -0.25em;
      margin-right: 8px;
    }

    .action-buttons {
      flex-shrink: 0;

      & > .btn {
        padding: 7px 10px;

        & > .svg-inline--fa {
          font-size: 16px;
        }
      }

      .row-dropdown .dropdown-toggle {
        height: 32px;
        padding: 7px 8px 4px 8px;
      }
    }

    @media all and (min-width: @screen-md-min) {
      .action-buttons,
      .btn-link-muted {
        opacity: 0;
        transition: opacity 200ms ease-in-out;
      }

      &:hover .action-buttons,
      &:hover .btn-link-muted,
      &:focus-within .action-buttons,
      &:focus-within .btn-link-muted {
        opacity: 1;
      }
    }
  }
}

.with-blocks {
  position: relative;

  .inline-edit-input {
    white-space: nowrap;

    .btn {
      left: -20px;
      display: inline-block;
    }

    .inline-edit-input-control {
      height: 2em;
      max-width: 200px;

      @media all and (min-width: @screen-md-min) {
        max-width: 350px;
      }

      @media all and (min-width: @screen-lg-min) {
        max-width: 450px;
      }
    }
  }

  .code-block .inline-edit-input .inline-edit-input-control {
    max-width: 250px;

    @media all and (min-width: @screen-md-min) {
      max-width: 400px;
    }

    @media all and (min-width: @screen-lg-min) {
      max-width: 550px;
    }
  }

  .fa-bars {
    box-sizing: content-box;
    position: absolute;
    left: 0;
    top: 17px;
    padding: 5px 5px 5px 0;
    font-size: 12px;
    opacity: 0;
    .fade-transition(opacity);
  }

  .code-block .fa-bars {
    top: 18px;
  }

  &:hover .box-header > .fa-bars,
  &:focus-within .box-header > .fa-bars,
  .code-block:hover > .fa-bars,
  .code-block:focus-within > .fa-bars {
    opacity: 1;
  }
}

.full-screen-modal {
  .code-block-name {
    max-width: 50%;
    .overflow-break-anywhere();
  }

  .code-name {
    flex-basis: 50%;
    flex-grow: 1;
    .overflow-break-anywhere();
  }
}

.block-button {
  &.new-block-button {
    padding: 0;

    .btn-block {
      border-radius: @border-radius-large;
      background: @white;
      color: @primary-500-base;
      letter-spacing: @letter-spacing-narrower;
      box-shadow: @box-shadow;

      &:hover,
      &:focus {
        color: @primary-500-base;
        background: @neutral-50;
      }

      .fa-clock,
      .fa-spinner {
        font-size: 13px;
      }
    }
  }
}
