.box-panel-content.paginated {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  flex: 1;

  & > div:not(.box-navigation) {
    flex: 1;
  }

  .box-navigation {
    text-align: center;

    .btn {
      width: 14px;
      height: 14px;
      margin: 0px;
      padding: 5px;
      box-sizing: content-box;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:after {
        content: '';
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @neutral-400;
        opacity: 0.5;
        border-radius: @border-round;
      }

      &:hover:after,
      &:focus:after,
      &:active:after,
      &.active:after {
        background: @neutral-400;
        opacity: 1;
      }
    }
  }
}
