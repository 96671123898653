table.params-table {
  width: 100%;
  font-family: @font-family-monospace;

  th {
    color: @neutral-400;
    font-weight: 500;
    text-align: left;
  }

  td {
    font-weight: 500;
    text-align: right;
    padding-left: 15px;
    .overflow-break-anywhere();
  }
}
