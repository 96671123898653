@import url('bootstrap/less/variables');
@import url('bootstrap/less/mixins');
@import url('bootstrap/less/normalize');
@import url('bootstrap/less/scaffolding');
@import url('bootstrap/less/type');
@import url('bootstrap/less/code');
@import url('bootstrap/less/grid');
@import url('bootstrap/less/tables');
@import url('bootstrap/less/forms');
@import url('bootstrap/less/buttons');
@import url('bootstrap/less/component-animations');
@import url('bootstrap/less/dropdowns');
@import url('bootstrap/less/button-groups');
@import url('bootstrap/less/input-groups');
@import url('bootstrap/less/navs');
@import url('bootstrap/less/navbar');
@import url('bootstrap/less/breadcrumbs');
@import url('bootstrap/less/labels');
@import url('bootstrap/less/progress-bars');
@import url('bootstrap/less/list-group');
@import url('bootstrap/less/panels');
@import url('bootstrap/less/wells');
@import url('bootstrap/less/close');
@import url('bootstrap/less/modals');
@import url('bootstrap/less/tooltip');
@import url('bootstrap/less/popovers');
@import url('bootstrap/less/utilities');
@import url('bootstrap/less/responsive-utilities');

@import url('./variables');

@import url('./box');
@import url('./breadcrumb');
@import url('./buttons');
@import url('./dashboard-welcome-widget');
@import url('./dropdown-menu');
@import url('./form');
@import url('./full-screen-modal');
@import url('./graph');
@import url('./skeleton');
@import url('./helpers');
@import url('./helpers-flexbox');
@import url('./icons');
@import url('./inline-edit-text-input');
@import url('./label');
@import url('./list-group');
@import url('./modal');
@import url('./floating-notifications');
@import url('./paginated-box');
@import url('./panel');
@import url('./popover');
@import url('./predefined-search');
@import url('./project-select-results');
@import url('./safari');
@import url('./select-group');
@import url('./separator');
@import url('./schedule-form');
@import url('./sidebar');
@import url('./react-table');
@import url('./tabs');
@import url('./table');
@import url('./tooltip');
@import url('./transitions');
@import url('./user');
@import url('./vault');
@import url('./well');

// imports 3rd party themes
// let's reuse KBC theme variables
@import url('./theme-kbc-codemirror');
@import url('./theme-kbc-dimple');
@import url('./theme-kbc-jsoneditor');
@import url('./theme-kbc-markdown');
@import url('./theme-kbc-rc-switch');
@import url('./theme-kbc-react-select');
@import url('./theme-kbc-row-disabled');
@import url('./theme-kbc-sortable');

// sections
@import url('./alert');
@import url('./blank-page');
@import url('./flow');
@import url('./info-row');
@import url('./global-search');
@import url('./gooddata-dashboard');
@import url('./sidemenu');
@import url('./sections/dashboard');
@import url('./sections/info-alert');
@import url('./sections/settings-page');
@import url('./sections/git-sync-page');
@import url('./sections/job-detail');
@import url('./sections/templates');
@import url('./sections/snowflake-partner-connect');
@import url('./components/dbt');
@import url('./components/animation-bar');
@import url('./components/backend-size-select');
@import url('./components/date-picker');
@import url('./components/dev-mode-bar');
@import url('./components/description-box');
@import url('./components/searchbar');
@import url('./components/slider-picker');
@import url('./components/billing');
@import url('./components/features-list');
@import url('./components/files-dropzone');
@import url('./components/empty-jobs-graph');
@import url('./components/data-sample-table');
@import url('./components/directory');
@import url('./components/overlay-opacity');
@import url('./components/destination-table-selector');
@import url('./components/select-timezone-dropdown');
@import url('./components/search-context-dropdown');
@import url('./components/split-diff');
@import url('./components/sort-icon');
@import url('./components/inline-edit-input');
@import url('./components/job-status-label');
@import url('./components/job-status-icon');
@import url('./components/jobs-advanced-search');
@import url('./components/modal-action-button');
@import url('./components/search-panel');
@import url('./components/support-form');
@import url('./components/simplified-form');
@import url('./components/tables-by-buckets');
@import url('./components/provisioning-credentials-modal');
@import url('./components/genericVariablesUI');
@import url('./components/genericCodeBlocksUI');
@import url('./components/genericNoCodeBlocksUI');
@import url('./components/dropdown-toggle-icon');
@import url('./components/demo-preview.less');
@import url('./utilities/spacing');
@import url('./params-table');
@import url('./params-list');

@tailwind base;
@tailwind utilities;
@tailwind components;

@import url('design/dist/style.css');

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local(''),
    url('@{assets-url}/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-regular.woff2')
      format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local(''),
    url('@{assets-url}/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-500.woff2')
      format('woff2');
}

@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local(''),
    url('@{assets-url}/v0.17.0-0-g78ee112/inter-v12-latin-ext_latin/1.0.0/inter-v12-latin-ext_latin-700.woff2')
      format('woff2');
}

html {
  font-size: 16px;
}

body {
  font-family: @font-family-sans-serif;
  background: @neutral-50;
  min-height: 100%;
  margin: 0;
  overscroll-behavior: none;
  overflow: auto;
}

@media (min-width: @screen-xl) {
  .container {
    width: @container-xl;
  }
}

@media (min-width: @screen-xxl) {
  .container {
    width: @container-xxl;
  }
}

a {
  cursor: pointer;

  &:not(:where(.nav-link, .link-inherit, [role='menuitem'], [role='button'], [role='tab'])) {
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &:hover,
  &:focus {
    text-decoration: none !important;
  }

  &.dark {
    color: @neutral-800;

    &.muted {
      color: @neutral-400;
    }

    &:hover,
    &:focus {
      color: @neutral-800;
    }
  }
}

h1 {
  font-size: 30px;
}

h2 {
  color: @neutral-900;
  font-size: 19px;
}

h3 {
  color: @neutral-900;
  font-size: 16px;
}

h4 {
  color: @neutral-900;
  font-size: 14px;
  margin-bottom: 8px;

  .fa-spinner {
    font-size: 11px;
  }
}

hr {
  width: 100%;
  margin-top: 18px;
  margin-bottom: 18px;
  border-top: @border;

  &.wider {
    margin-right: -8px;
    margin-left: -12px;
  }

  &.split::after {
    content: 'OR';
    position: absolute;
    width: 35px;
    left: 0;
    right: 0;
    margin: -10px auto 0;
    text-align: center;
    font-size: 11px;
    line-height: 18px;
    background: @white;
  }
}

ul,
ol {
  padding-left: 20px;

  li > a:has(> .truncated:first-child) {
    display: inline-flex;
  }
}

textarea {
  resize: vertical;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  font-size: 75%;
  color: @neutral-400;
}

mark {
  color: @secondary-500-base;
  background: @secondary-100;
  padding: 0;
  font-weight: 500;
}

#main-content:not(:has(> .main-content-body > .blank-page)) {
  padding-bottom: 8em;
}

.help-block {
  font-size: 13px;

  &.text-danger {
    color: @error-500-base;
  }
}

.text-warning {
  color: @warning-500-base;
}

a,
button {
  &:focus:not(:focus-visible) {
    outline: none !important;
  }

  &:focus-visible {
    outline: 4px dashed black;
  }
}
