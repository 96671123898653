.pay-form {
  .box-container.custom-two-columns {
    .box-container.two-columns();

    @media (min-width: @screen-md) {
      grid-template-columns: 1fr 2fr;
    }

    .pay-form-fields {
      .form-group:has(.control-label) {
        display: grid;
        grid-template-columns: 1fr 4fr;
        grid-auto-flow: column;
        gap: 24px;
        align-items: center;

        &.two-inputs-form-group {
          grid-template-columns: 1fr 4fr 1fr 4fr;
        }

        .control-label {
          width: 90px;
          margin-bottom: 0;
          text-align: right;
          line-height: 20px;

          &:first-of-type {
            width: 136px;

            @media (min-width: @screen-lg) {
              width: 169px;
            }

            @media (min-width: @screen-xl) {
              width: 202px;
            }

            @media (min-width: @screen-xxl) {
              width: 268px;
            }
          }
        }
      }
    }

    .pay-form-fields,
    .top-up-form {
      .form-group.with-margin,
      & > *:not(:has(.control-label)) {
        margin-left: 160px;

        @media (min-width: @screen-lg) {
          margin-left: 193px;
        }

        @media (min-width: @screen-xl) {
          margin-left: 226px;
        }

        @media (min-width: @screen-xxl) {
          margin-left: 290px;
        }
      }
    }
  }

  hr {
    margin: 32px 0 15px 0;
  }
}

.credits-picker {
  display: grid;
  gap: 16px;
  margin: 0;

  @media all and (min-width: @screen-md-min) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  & > .box {
    min-width: 300px;
    max-width: initial;
    padding: 0px 18px 24px 18px;
    margin: 0;

    .box-header {
      padding: 4px 6px 0px 6px;
    }

    .box-panel-content {
      padding: 0px 6px;
    }
  }

  .radio {
    margin: 0;

    label {
      width: 100%;
      padding: 25px;
      border-radius: @border-radius-large;
      border: @border;

      h2 {
        margin-bottom: 5px;
      }

      &:hover,
      &:has(input[type='radio']:checked) {
        border-color: @secondary-500-base;
        outline: 1px solid @secondary-500-base;
      }

      input[type='radio'] {
        bottom: 16px;
        right: 16px;
        margin: 0;
        accent-color: @border-light;
        cursor: pointer;
      }
    }
  }
}

.saved-card {
  .form-control {
    padding: 15px 16px;
    height: auto;

    img,
    svg {
      height: 24px;
      margin-right: 12px;
    }
  }
}

.top-up-form {
  &.disabled {
    .Select,
    .form-control,
    .control-label:has(.switch) {
      cursor: not-allowed;
      opacity: 0.4;

      .switch {
        opacity: 1;
      }
    }
  }

  .form-control[disabled],
  .Select.react-select--is-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  .top-up-form-inputs {
    margin-top: 26px;
    display: grid;
    grid-template-columns: auto 228px;
    align-items: end;

    @media all and (max-width: @screen-lg) {
      grid-template-columns: 5fr 2fr;
    }
  }

  &.modal-body .top-up-form-inputs {
    margin-top: 16px;
  }
}
