.dimple-box:not(:empty) {
  margin: 0px 28px @box-margin 28px;

  .dimple-chart {
    .dimple-bar {
      &:hover {
        fill: @neutral-400 !important;
        stroke: @neutral-400 !important;
      }

      &.dimple-error {
        &.dimple-iscurrentjob {
          fill: @error-500-base !important;
          stroke: @error-500-base !important;
        }

        &:hover {
          fill: @error-600 !important;
          stroke: @error-600 !important;
        }
      }

      &.dimple-success {
        &.dimple-iscurrentjob {
          fill: @primary-500-base !important;
          stroke: @primary-500-base !important;
        }

        &:hover {
          fill: @primary-600 !important;
          stroke: @primary-600 !important;
        }
      }

      &.dimple-warning {
        &.dimple-iscurrentjob {
          fill: @warning-600 !important;
          stroke: @warning-600 !important;
        }

        &:hover {
          fill: @warning-700 !important;
          stroke: @warning-700 !important;
        }
      }

      &.dimple-terminated {
        &.dimple-iscurrentjob {
          fill: @neutral-400 !important;
          stroke: @neutral-400 !important;
        }

        &:hover {
          fill: @neutral-500-base !important;
          stroke: @neutral-500-base !important;
        }
      }
    }

    .dimple-axis {
      .dimple-custom-axis-line {
        stroke: @neutral-100 !important;
      }

      .dimple-custom-axis-label {
        font-family: @font-family-sans-serif !important;
        font-size: 11px !important;
        fill: @neutral-500-base;
      }

      &.dimple-axis-y .dimple-custom-axis-label {
        fill: @neutral-400;
      }
    }

    .dimple-title {
      font-family: @font-family-sans-serif !important;
      font-size: 14px !important;
      fill: @neutral-400;
    }
  }

  .dimple-trend-line {
    stroke-dasharray: 2;

    &.dimple-rising {
      stroke: @error-500-base !important;
    }

    &.dimple-no-rising {
      stroke: @primary-500-base !important;
    }
  }

  &.latest-import-graph {
    margin: 0 -10px -20px -10px;
  }

  .dimple-tooltip-dropline {
    display: none;
  }
}

.dimple-bar {
  position: relative;
  width: 12px;
  opacity: 1 !important;
  fill: @neutral-200 !important;
  stroke: @neutral-200 !important;
}

.dimple-tooltip {
  font-family: @font-family-sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
  fill: @neutral-800 !important;
  stroke: none !important;

  &.dimple-custom-tooltip-box {
    transform: scale(1.06, 1.25) translate(-4px, -4px);
  }

  &.dimple-custom-tooltip-label {
    fill: @white !important;
  }

  &.success ~ .dimple-custom-tooltip-label:first-of-type {
    fill: @primary-400 !important;
  }

  &.warning ~ .dimple-custom-tooltip-label:first-of-type {
    fill: #b88d00 !important;
  }

  &.terminated ~ .dimple-custom-tooltip-label:first-of-type {
    fill: @neutral-200 !important;
  }

  &.error ~ .dimple-custom-tooltip-label:first-of-type {
    fill: #ec001d !important;
  }
}

.run-results-graph > svg {
  position: relative;
  overflow: initial;

  .dimple-bar {
    width: 8px;

    &.dimple-success {
      fill: @primary-200 !important;
      stroke: @primary-200 !important;
    }
  }

  .dimple-chart + g > g {
    transform: translate(120px, 0px) !important; // force tooltip next to graph
  }
}

.jobs-graph {
  position: relative;

  .btn {
    position: absolute;
    border-color: @neutral-150;

    &:hover {
      border-color: @neutral-150;
      background-color: @neutral-50 !important;
      color: @secondary-500-base;
    }

    &:first-child {
      left: 24px;
    }

    &:last-child {
      right: 24px;
    }
  }

  svg {
    overflow: visible;
  }

  .dimple-box:not(:empty) {
    margin: 0px 72px @box-margin 72px;

    .dimple-gridline .tick line {
      stroke: #d9dee6 !important;
    }

    .dimple-axis {
      .domain.dimple-custom-axis-line {
        stroke: none !important;
      }

      line.dimple-custom-axis-line {
        stroke-opacity: 0;
      }

      text.dimple-custom-axis-label {
        transform: translate(-20px, 5px);

        tspan {
          font-size: 12px;
        }
      }

      &.dimple-axis-y {
        transform: translate(94px, -6px);
      }

      &.dimple-axis-x text.dimple-custom-axis-label {
        transform: rotate(-45deg) translate(-43px, 10px) !important;

        &.with-year {
          transform: rotate(-45deg) translate(-48px, 10px) !important;
        }
      }

      @media all and (max-width: @screen-lg) {
        &.dimple-axis-x text.dimple-custom-axis-label {
          transform: rotate(270deg) translate(-44px, -10px) !important;
          text-anchor: end !important;

          &.with-year {
            transform: rotate(270deg) translate(-44px, -10px) !important;
          }

          tspan {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.box .jobs-graph {
  .dimple-box:not(:empty) {
    margin: 0px 48px @box-margin 48px;
  }

  .btn {
    margin-bottom: 50px;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }
  }
}
