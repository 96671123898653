.form-control {
  box-shadow: none;
  line-height: @font-size-base;
}

.form-control[type='date'],
.form-control[type='datetime-local'] {
  padding: 0px 16px;
}

label,
.control-label {
  font-weight: 400;
}

.input-group-addon {
  background: darken(@input-bg, 5%);
}

.form-group.has-error {
  & .control-label {
    color: inherit;
  }

  .form-control:not([disabled]) {
    border: 1px solid @transparent;
    box-shadow: none;
    color: @error-500-base;
    background-color: @error-100;

    &:focus {
      border: 1px solid @transparent;
      box-shadow: none;
    }
  }
}

textarea.form-control {
  line-height: 20px;
}

select.form-control {
  padding: 0px 16px;
}

.Select + .help-block,
.form-control:not(.inline-edit-input-control) + .help-block {
  margin-left: 1px;
  margin-right: 1px;
}

.form-control:not(.inline-edit-input-control):focus,
.Select .react-select__control--is-focused {
  border: 1px solid @secondary-500-base !important;
  box-shadow: @outline-shadow;
  border-radius: @border-radius;
}

.radio {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid @transparent;

  label {
    padding-left: 22px;
  }

  &.disabled {
    opacity: 0.4;
  }

  input[type='radio'] {
    width: 16px;
    height: 16px;
    margin-top: 2px;
    margin-left: -22px;
  }
}

.above-table-with-buttons .common-checkbox {
  margin-right: 10px;
  margin-left: 4px;
}

@media all and (min-width: @screen-sm-min) {
  .form-horizontal .control-label {
    padding-top: 10px;
    text-align: left;
  }
}

.form-control-static {
  .overflow-break-anywhere();

  &.form-control {
    background-color: @neutral-150;
  }

  .switch-state-link {
    cursor: pointer;
    color: @neutral-800;
    text-decoration: none;
    button {
      margin-right: 0.5em;
    }
  }
}

.input-error .inline-edit-input .form-control,
.inline-edit-input.invalid .form-control {
  border-bottom-color: @error-500-base;
}

.input-with-addon {
  position: relative;

  .form-control {
    padding-right: 40px;
  }

  .addon {
    position: absolute;
    bottom: 8px;
    right: 13px;
  }
}

.form-groups {
  display: flex;
  gap: 16px;

  & > .form-group {
    flex: 1 0 0;
  }
}

.inputs-group {
  margin: 8px 0 18px;
  padding: 16px;
  border: @border;
  border-radius: @border-radius;
}

.common-checkbox {
  display: inline-flex;
  align-items: center;
  border: 1px solid @transparent;

  &:not(.disabled) * {
    cursor: pointer;
  }

  input[type='checkbox'] {
    transform: scale(calc(16 / 13));
    margin: 0 0 0 1px;

    &:not(:checked) {
      opacity: 0.4;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  label {
    margin: 0;
    padding-left: 10px;
  }
}

* + .common-checkbox:has(label),
.common-checkbox.block {
  display: flex;

  label {
    flex: 1;
  }
}
