.slider-picker {
  margin: 26px 0 24px 0;

  &:before {
    content: '';
    position: relative;
    top: 9px;
    height: 4px;
    display: block;
    border-radius: 2px;
    background: @neutral-100;
  }

  .picker-values {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .radio-inline {
    flex: 1;
    margin: -1em 0;
    padding: 1em 0;
    display: flex;
    max-width: 60px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .picker-label {
      margin-top: 30px;
    }

    input[type='radio'] {
      position: relative;
      margin: 0;
      cursor: pointer;

      &:checked + .picker-label {
        color: @secondary-500-base;
        font-weight: 500;
      }
    }
  }
}
