.row-disabled {
  & > td:not(.pr-1),
  & > .td:not(.pr-1) {
    opacity: 0.5;
  }
}

.hoverable-actions-with-replacement.row-disabled {
  & > td:last-of-type,
  & > .td:last-of-type {
    opacity: 1;

    .actions-container > .not-actions {
      opacity: 0.5;
    }
  }
}
