.box.tables-by-buckets {
  .box-content {
    padding: 8px;

    .panel {
      &:not(.panel-show-details) {
        border: 2px solid @neutral-50;

        &:not(:last-of-type) {
          margin-bottom: 8px;
        }
      }

      & + .panel {
        margin-top: 8px;
      }
    }
  }
}

.bucket-panel {
  .svg-inline--fa + h4 {
    display: inline-block;
    padding-left: 8px;
  }

  .table {
    &-hover {
      padding-top: 4px;
    }

    .tbody {
      a.tr:hover {
        color: inherit;
      }
    }
  }
}
