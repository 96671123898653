#sidemenu :is(.project-select, .branch-select) {
  position: initial;
  margin: 4px 13px 0;

  &.disabled > #select-brach-dropdown-button,
  &.disabled > #select-project-dropdown-button {
    cursor: default;
    opacity: 1;
  }

  .dropdown-toggle > .project-box {
    flex: 1;
    color: @neutral-800;

    .organization-name {
      color: @neutral-400;
    }
  }

  .dropdown-menu {
    box-shadow: none;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: calc(100% + 1px);
    overflow-y: auto;
    border-radius: 0;
    border: none;
    margin: 0;

    .searchbar {
      margin-bottom: 16px;

      .searchbar-input {
        font-size: 14px;
      }
    }

    h3 {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      padding: 8px;
    }

    & > .list-unstyled > li.text-muted {
      padding: 8px;
    }

    .subnav-link {
      display: flex;
      justify-items: flex-start;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: @neutral-800;
      text-decoration: none;
      padding: 8px;
      border-radius: @border-radius;
      letter-spacing: initial;
      text-transform: initial;

      .hoverable-buttons {
        display: none;
        gap: 10px;
        margin-right: 0;
        margin-left: auto;

        .btn-default {
          background: @transparent;
        }
      }

      .svg-inline--fa {
        bottom: 0;
      }

      &:hover,
      &:focus,
      &:focus-within {
        background-color: @neutral-100;

        .hoverable-buttons {
          display: inline-flex;
        }
      }

      &.active {
        font-weight: 700;
      }

      &.group-header {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: @neutral-400;
        padding: 16px 8px 4px;

        &:first-child {
          padding-top: 8px;
        }
      }
    }
  }

  &.open::after {
    content: '';
    position: fixed;
    left: 320px;
    right: 0;
    top: 0;
    bottom: 0;
    background: @neutral-800;
    opacity: 0.4;
    pointer-events: none;
  }
}
