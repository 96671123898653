.directory-filter {
  border: @border;
  border-radius: @border-radius;
  padding: 16px;

  h3 {
    margin-bottom: 14px;
  }

  .common-checkbox {
    margin-bottom: 12px;

    &:hover .text-muted,
    &:focus .text-muted {
      color: @secondary-600 !important;
    }
  }
}
