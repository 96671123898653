.nocode-step-wrapper {
  padding: 0 24px 24px;

  .nocode-blocks {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;

    &.with-columns {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .nocode-block {
      padding: 16px;
      background-color: @white;
      border: @border;
      border-radius: @border-radius;
      text-align: left;
      text-transform: none;

      &:hover {
        background-color: @neutral-50;
        border: @border;
      }

      .icon-square-background {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-width: 42px;
        height: 42px;
        background-color: @secondary-500-base;
        margin-right: 12px;
        border-radius: @border-radius;

        svg:nth-child(2) {
          right: -5px;
          bottom: -5px;
        }
      }

      .action-buttons {
        flex-shrink: 0;

        & > .btn {
          padding: 7px 10px;

          & > .svg-inline--fa {
            font-size: 16px;
          }
        }
      }

      @media all and (min-width: @screen-md-min) {
        .action-buttons,
        .btn-link-muted {
          opacity: 0;
          transition: opacity 200ms ease-in-out;
        }

        &:hover .action-buttons,
        &:hover .btn-link-muted,
        &:focus-within .action-buttons,
        &:focus-within .btn-link-muted {
          opacity: 1;
        }
      }
    }
  }
}
