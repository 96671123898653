.nav-tabs,
.tabs-with-border-wrapper {
  border-bottom: @border;
}

.tabs-with-border-wrapper {
  margin-bottom: 16px;

  .nav-tabs {
    border-bottom: none;
  }
}

div:not(.tabs-inside-modal) > .nav-tabs {
  border: none;

  & > li {
    margin: 0 16px;

    @media all and (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
      margin: 0 16px;
    }

    &:first-of-type {
      margin-left: 0px;
    }

    & > a,
    & > button {
      color: @neutral-400;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      padding: 10px 0px 16px;
      margin: 0;
      border: none;
      border-bottom: 3px solid @transparent;
    }

    &:not(.disabled) > button,
    &:not(.disabled) > a {
      .fade-transition();
    }

    & > a:hover,
    & > a:focus,
    &.active > a,
    &.active > a:hover,
    &.active > a:focus,
    & > button:hover,
    &.active > button {
      color: @secondary-500-base;
      background-color: @transparent;
      border: none;
      border-bottom: 3px solid @secondary-500-base;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      > a:hover,
      > a:focus,
      > a:active {
        color: @neutral-400;
        border-bottom: none;
      }
    }
  }
}

.tabs-inside-modal {
  .nav-tabs {
    padding: 0 25px;
    margin: 0 -12px 15px -12px;
  }

  .tab-content {
    clear: both;
    padding-left: 0;
    padding-right: 0;
  }
}

.tab-content .tab-pane.with-border {
  border-top: @border;
}
