.dropdown-toggle-icon {
  font-size: 14px;
  padding: 0;
  margin-left: 10px;
  color: @neutral-400;

  &.with-background.fa-angle-up {
    color: @white;
    background-color: @neutral-400;
    border-radius: 3px;
  }
}
