*, ::before, ::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
::backdrop {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x:  ;
    --tw-pan-y:  ;
    --tw-pinch-zoom:  ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position:  ;
    --tw-gradient-via-position:  ;
    --tw-gradient-to-position:  ;
    --tw-ordinal:  ;
    --tw-slashed-zero:  ;
    --tw-numeric-figure:  ;
    --tw-numeric-spacing:  ;
    --tw-numeric-fraction:  ;
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur:  ;
    --tw-brightness:  ;
    --tw-contrast:  ;
    --tw-grayscale:  ;
    --tw-hue-rotate:  ;
    --tw-invert:  ;
    --tw-saturate:  ;
    --tw-sepia:  ;
    --tw-drop-shadow:  ;
    --tw-backdrop-blur:  ;
    --tw-backdrop-brightness:  ;
    --tw-backdrop-contrast:  ;
    --tw-backdrop-grayscale:  ;
    --tw-backdrop-hue-rotate:  ;
    --tw-backdrop-invert:  ;
    --tw-backdrop-opacity:  ;
    --tw-backdrop-saturate:  ;
    --tw-backdrop-sepia:  
}
.tw-mt-6 {
    margin-top: 1.5rem
}
.tw-box-border {
    box-sizing: border-box
}
.tw-flex {
    display: flex
}
.tw-cursor-not-allowed {
    cursor: not-allowed
}
.tw-cursor-pointer {
    cursor: pointer
}
.tw-flex-row {
    flex-direction: row
}
.tw-items-end {
    align-items: flex-end
}
.tw-items-center {
    align-items: center
}
.tw-justify-center {
    justify-content: center
}
.tw-gap-12 {
    gap: 3rem
}
.tw-gap-2 {
    gap: 0.5rem
}
.tw-rounded {
    border-radius: 0.25rem
}
.tw-border-2 {
    border-width: 2px
}
.tw-border-solid {
    border-style: solid
}
.tw-border-none {
    border-style: none
}
.tw-border-transparent {
    border-color: rgba(255,255,255,0)
}
.tw-bg-neutral-50 {
    background-color: #f2f4f7
}
.tw-bg-primary-500 {
    background-color: #1ec71e
}
.tw-bg-secondary-500 {
    background-color: #1f8fff
}
.tw-bg-white {
    background-color: #fff
}
.tw-px-4 {
    padding-left: 1rem;
    padding-right: 1rem
}
.tw-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem
}
.tw-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem
}
.tw-text-secondary-500 {
    color: #1f8fff
}
.tw-text-white {
    color: #fff
}
.tw-opacity-60 {
    opacity: 0.6
}
.tw-shadow-\[0_0_0_1px\] {
    --tw-shadow: 0 0 0 1px;
    --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.tw-shadow-secondary-500 {
    --tw-shadow-color: #1f8fff;
    --tw-shadow: var(--tw-shadow-colored)
}
.tw-outline {
    outline-style: solid
}
.tw-outline-0 {
    outline-width: 0px
}
.tw-outline-1 {
    outline-width: 1px
}
.tw-outline-error-500 {
    outline-color: #e00025
}
.active\|focus\:tw-shadow-\[0_0_0_5px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:active {
    --tw-shadow: 0 0 0 5px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.active\|focus\:tw-outline-2:active {
    outline-width: 2px
}
.active\|focus\:tw-outline-secondary-500:active {
    outline-color: #1f8fff
}
.active\|focus\:tw-shadow-\[0_0_0_5px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:focus {
    --tw-shadow: 0 0 0 5px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.active\|focus\:tw-outline-2:focus {
    outline-width: 2px
}
.active\|focus\:tw-outline-secondary-500:focus {
    outline-color: #1f8fff
}
.active\|focus\:tw-shadow-\[0_0_0_5px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:focus-within {
    --tw-shadow: 0 0 0 5px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 5px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.active\|focus\:tw-outline-2:focus-within {
    outline-width: 2px
}
.active\|focus\:tw-outline-secondary-500:focus-within {
    outline-color: #1f8fff
}
.\[\&\:not\(\:disabled\)\]\:hover\:tw-bg-primary-700:hover:not(:disabled) {
    background-color: #189f18
}
.\[\&\:not\(\:disabled\)\]\:hover\:tw-bg-secondary-500:hover:not(:disabled) {
    background-color: #1f8fff
}
.\[\&\:not\(\:disabled\)\]\:hover\:tw-bg-secondary-700:hover:not(:disabled) {
    background-color: #075fb8
}
.\[\&\:not\(\:disabled\)\]\:hover\:tw-text-white:hover:not(:disabled) {
    color: #fff
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-border-secondary-500:active:not(:disabled) {
    border-color: #1f8fff
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-shadow-\[0_0_0_3px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:active:not(:disabled) {
    --tw-shadow: 0 0 0 3px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-border-secondary-500:focus:not(:disabled) {
    border-color: #1f8fff
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-shadow-\[0_0_0_3px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:focus:not(:disabled) {
    --tw-shadow: 0 0 0 3px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-border-secondary-500:focus-within:not(:disabled) {
    border-color: #1f8fff
}
.\[\&\:not\(\:disabled\)\]\:active\|focus\:tw-shadow-\[0_0_0_3px_rgba\(31\2c 143\2c 255\2c 0\.2\)\]:focus-within:not(:disabled) {
    --tw-shadow: 0 0 0 3px rgba(31,143,255,0.2);
    --tw-shadow-colored: 0 0 0 3px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
