.demo-preview {
  .product-fruits-checklists {
    position: relative;
    z-index: 1;
  }

  .box-container {
    &.four-columns,
    &.two-columns {
      > a.box,
      > .btn.box {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 12px 24px;
        font-weight: 500;
        text-decoration: none;
        color: @neutral-800;
        border-radius: @border-radius-large;

        &:hover,
        &:focus {
          color: @secondary-600;
          background-color: @white;
          box-shadow: @box-shadow;
        }
      }
    }
  }

  .box.box-collapsible .box-header.clickable {
    &:hover {
      .box-title {
        color: @secondary-600;
      }
    }
  }
}
