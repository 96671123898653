.modal-action-button {
  &:not(:first-child) {
    margin: 8px 0 0;
  }

  > button.btn {
    padding: 12px 16px;
    text-transform: initial;
    text-align: left;
    border: none;
    border-radius: @border-radius-large;
    font-weight: 400;
    letter-spacing: normal;
    font-size: 14px;
    white-space: normal;

    display: flex;
    align-items: center;

    &:active {
      box-shadow: none;
    }

    &:hover {
      background: @neutral-100;

      .modal-action-button-text {
        strong {
          color: @secondary-600;
        }

        small {
          color: @neutral-400;
        }
      }

      .modal-action-button-arrow {
        color: @neutral-800;
      }
    }

    .modal-action-button-icon {
      margin-right: 16px;
    }

    .modal-action-button-text {
      flex-grow: 1;
      flex-shrink: 1;
      strong {
        display: block;
        margin-bottom: 4px;
        color: @neutral-900;
        font-weight: 500;
      }
      small {
        display: block;
      }
    }

    .modal-action-button-arrow {
      color: @neutral-200;
      padding-left: 1em;
      font-size: 18px;
    }
  }
}
