#main-app {
  display: grid;
  grid-template-columns: 248px auto;
  grid-template-areas: 'sidemenu app';

  &.sidemenu-collapsed {
    grid-template-columns: 70px auto;

    #sidemenu {
      width: 70px;

      #global-search-button,
      #sidemenu-collapse-button {
        display: none;
      }

      .sidemenu-header:hover {
        .header-logo {
          display: none;
        }
        #sidemenu-collapse-button {
          display: inline-flex;
          margin: 20px 19px;
        }
      }

      .nav-link,
      .btn-group > .btn {
        height: 44px;
        width: 44px;
        padding: 12px;
      }

      .btn-group > .btn.avatar {
        padding: 6px;
      }
    }
  }

  .sidemenu-slide-exit-done,
  .sidemenu-slide-exit-active,
  .sidemenu-slide-enter-active {
    .nav,
    .sidemenu-header,
    .bottom > .btn-group,
    #select-branch-dropdown-button {
      overflow: hidden;
    }

    & ~ #main-content .full-screen-modal.full-screen-editor.half-screen {
      left: 90px;
    }
  }
}

#sidemenu {
  top: 0;
  z-index: 1039;
  position: fixed;
  grid-area: sidemenu;
  background-color: @white;
  width: 248px;
  max-height: 100vh;
  transition: width 120ms cubic-bezier(0.2, 0, 0, 1);
  border-right: 1px solid @neutral-150;
}

#container-header {
  position: sticky;
  height: 66px;
  z-index: 11;
  top: 0;
  background-color: @neutral-50;
  margin: 16px 0 12px 0;
  padding: 12px 32px;
  transition: background-color 120ms cubic-bezier(0.2, 0, 0, 1);

  @media all and (max-width: @screen-xl) {
    padding: 12px 24px;
  }
}

body.sticky #container-header {
  background-color: @white;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-bottom: 1px solid @neutral-150;
  }
}

#main-content {
  grid-area: app;
  min-width: 100%;

  .main-content-body {
    padding: 0 32px;

    @media all and (max-width: @screen-xl) {
      padding: 0 24px;
    }
  }
}

#create-project-bar + #main-app {
  #sidemenu {
    top: 56px;
    height: calc(100vh - 56px);
  }

  #container-header {
    top: 56px;
  }
}
