.blank-page {
  text-align: center;

  img.intro-image {
    display: inline-block;
    max-height: 450px;
    margin-bottom: @box-margin;

    &.intro-image-small {
      max-height: 240px;
    }

    &[width] {
      max-height: initial;
    }
  }

  .intro-video {
    display: inline-block;
    max-height: 670px;
    margin-top: 32px;
  }

  h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 16px;
  }

  .intro-text {
    color: @neutral-400;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 auto 32px;
    max-width: 800px;
  }

  .intro-action {
    margin: 32px auto;
  }

  &.empty-page {
    min-height: 70vh;
    margin-top: 60px;
    border-radius: 6px;
    box-shadow: @box-shadow;
  }
}
