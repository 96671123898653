.list-group-item {
  padding: 15px 12px;
  background: none;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid @list-group-border;

  &.active,
  &.active:hover,
  &.active:focus {
    border-color: @list-group-border;
    background-color: @secondary-100;
    color: @neutral-500-base;
  }

  &:first-child {
    border-radius: 0;
  }

  &:last-child {
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: none;
  }
}

a.list-group-item {
  &:hover,
  &:focus {
    color: @neutral-500-base;
  }
}
