.well {
  box-shadow: none;
  padding: 18px 20px;
  margin: 1em 0px;

  .well-title {
    display: block;
    color: @neutral-400;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 6px;
  }

  .fa-folder {
    color: @neutral-400;
    width: 20px;
    height: auto;
  }
}
