.status-circle {
  color: @neutral-600;

  &.created,
  &.processing {
    color: @secondary-500-base;
  }

  &.waiting,
  &.cancelled {
    color: @neutral-400;
  }

  &.success {
    color: @primary-500-base;
  }

  &.error {
    color: @error-500-base;
  }

  &.terminated,
  &.terminating {
    color: @neutral-500-base;
  }

  &.warning {
    color: @warning-500-base;
  }
}
