.make-spacing-classes(@n, @i: 0) when (@i =< @n) {
  @base: 1em;

  .m-@{n} {
    margin: @n * @base !important;
  }
  .mt-@{n} {
    margin-top: @n * @base !important;
  }
  .mr-@{n} {
    margin-right: @n * @base !important;
  }
  .mb-@{n} {
    margin-bottom: @n * @base !important;
  }
  .ml-@{n} {
    margin-left: @n * @base !important;
  }

  .p-@{n} {
    padding: @n * @base !important;
  }
  .pt-@{n} {
    padding-top: @n * @base !important;
  }
  .pr-@{n} {
    padding-right: @n * @base !important;
  }
  .pb-@{n} {
    padding-bottom: @n * @base !important;
  }
  .pl-@{n} {
    padding-left: @n * @base !important;
  }

  .make-spacing-classes(@n - 1);
}

.make-spacing-classes(3);

// Based on https://zeroheight.com/7fbd3efca/p/539215-layout/t/18dc40
.make-spacing-classes-in-pixels(@n) {
  @base: 4px;

  .mp-@{n} {
    margin: @n * @base !important;
  }
  .mtp-@{n} {
    margin-top: @n * @base !important;
  }
  .mrp-@{n} {
    margin-right: @n * @base !important;
  }
  .mbp-@{n} {
    margin-bottom: @n * @base !important;
  }
  .mlp-@{n} {
    margin-left: @n * @base !important;
  }

  .pp-@{n} {
    padding: @n * @base !important;
  }
  .ptp-@{n} {
    padding-top: @n * @base !important;
  }
  .prp-@{n} {
    padding-right: @n * @base !important;
  }
  .pbp-@{n} {
    padding-bottom: @n * @base !important;
  }
  .plp-@{n} {
    padding-left: @n * @base !important;
  }
}

.make-spacing-classes-in-pixels(1);
.make-spacing-classes-in-pixels(2);
.make-spacing-classes-in-pixels(3);
.make-spacing-classes-in-pixels(4);
.make-spacing-classes-in-pixels(5);
.make-spacing-classes-in-pixels(6);
.make-spacing-classes-in-pixels(8);
.make-spacing-classes-in-pixels(10);
.make-spacing-classes-in-pixels(12);
.make-spacing-classes-in-pixels(16);
.make-spacing-classes-in-pixels(24);
