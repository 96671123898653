.section-dashboard {
  @media all and (min-width: @screen-md-min) {
    .section-boxes {
      display: grid;
      column-gap: @box-margin;
      grid-template-columns: 1fr 1fr 1fr;

      .vertical-boxes {
        display: grid;
      }

      .box-panel {
        margin-bottom: @box-margin;
      }
    }
  }

  .box-panel {
    min-height: 440px;

    .box-header {
      min-height: 68px;
      padding: 18px 8px 10px 12px;

      .box-title {
        line-height: 40px;
      }
    }

    &.box-panel-small {
      min-height: 204px;

      .box-header {
        padding-right: 2px;
      }
    }
  }

  .last-errors-item,
  .latest-configuration-edit-item {
    padding: 8px 12px 7px 12px;
    justify-content: unset;
    color: @neutral-800;
    border-radius: @border-radius-large;
    text-decoration: none;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    .component-meta {
      margin-right: auto;
      line-height: 18px;

      .text-muted:last-child {
        font-size: 12px;
        margin-top: 2px;
      }

      .text-success:last-child {
        font-weight: 500;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &[href]:hover,
    &[href]:focus {
      background: @neutral-100;

      .component-meta > *:first-child {
        color: @secondary-600;
      }

      .fa-chevron-right {
        color: @neutral-800 !important;
      }
    }
  }
}
