.panel:not(.panel-show-details) {
  border: @border;
  box-shadow: none;
  margin-bottom: 1em;

  + .panel {
    margin-top: 1em;
  }

  .panel-heading,
  .panel-body {
    border: none;
    padding: 0;
  }

  .panel-body {
    & > .form-horizontal,
    & > .save-buttons {
      padding: 1em;
    }
  }

  .panel-title {
    font-size: 13px;
    line-height: 18px;

    a[aria-expanded] {
      color: @neutral-400;
      display: block;
      padding: 12px 20px;
      text-decoration: none;

      .row {
        display: flex;
        align-items: center;
      }

      .table tr td,
      .table .tr .td {
        float: none;
        padding: 0;

        .btn {
          padding: 5px 8px;
        }
      }
    }

    &.smaller-padding a[aria-expanded] {
      padding: 12px 15px 12px 13px;
    }
  }

  &.disabled {
    opacity: 0.5;
  }
}

.box .with-panel .panel-show-details,
.box .only-with-panel .panel-show-details {
  margin-left: -20px;
  margin-right: -20px;

  .panel-heading {
    background: @neutral-100;
    border-radius: 2px;

    .panel-title {
      color: @neutral-400;
      text-align: left;
      font-size: 13px;
      line-height: 18px;

      a {
        display: block;
        padding: 5px 0;

        &:hover,
        &:active,
        &:focus {
          color: @secondary-500-base;
          text-decoration: none;
        }
      }
    }
  }
}

.panel-show-details {
  border: 0;
  box-shadow: none;

  .panel-collapse {
    overflow: hidden;

    &.in {
      overflow: visible;
    }
  }

  .panel-heading {
    border: 0;
    background-color: #fff;

    .panel-title {
      text-align: center;

      a {
        padding: 10px 0 0 0;
        display: inline-block;

        &:hover {
          color: @link-hover-color;
        }
      }
    }
  }

  .panel-body {
    padding: 20px 0 0 0;
  }

  &-bottom {
    display: flex;
    flex-direction: column-reverse;

    .panel-heading + .panel-collapse > .panel-body {
      padding: 0 20px 15px 20px;
      border-top: 0;
    }
  }
}

.panel.collapsible-panel {
  overflow: hidden;

  &.disabled * {
    cursor: not-allowed;
  }

  &:not(.disabled) .panel-heading > .panel-title > a:hover :is(.fa-angle-down, .fa-angle-up) {
    color: @secondary-600;
  }

  .panel-heading {
    background-color: @neutral-100;

    .panel-title a {
      color: @neutral-800;
      font-weight: 500;
      padding: 10px 15px;
    }
  }

  .panel-body:not(:has(> table)) {
    padding: 15px;
  }
}
