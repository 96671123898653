@svg-info: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.783 0.596875C15.7629 0.503359 15.7162 0.417632 15.6486 0.349999C15.581 0.282366 15.4952 0.235705 15.4017 0.215625C14.3955 0 13.6083 0 12.8239 0C9.5989 0 7.66452 1.725 6.22108 4H2.96483C2.68674 4.00024 2.41419 4.07777 2.17762 4.22396C1.94104 4.37014 1.74977 4.57921 1.62515 4.82781L0.080772 7.91531C0.0237384 8.02969 -0.00312092 8.15674 0.00274309 8.28441C0.00860711 8.41208 0.0469999 8.53614 0.114278 8.6448C0.181556 8.75347 0.275487 8.84314 0.387156 8.9053C0.498825 8.96747 0.624528 9.00006 0.752335 9H3.99515L3.29296 9.70219C3.10547 9.88972 3.00014 10.144 3.00014 10.4092C3.00014 10.6744 3.10547 10.9287 3.29296 11.1163L4.88358 12.7072C4.97645 12.8001 5.0867 12.8737 5.20804 12.924C5.32938 12.9743 5.45943 13.0002 5.59077 13.0002C5.72211 13.0002 5.85217 12.9743 5.97351 12.924C6.09485 12.8737 6.2051 12.8001 6.29796 12.7072L7.00015 12.005V15.25C7.00011 15.3778 7.03271 15.5034 7.09486 15.615C7.15701 15.7267 7.24664 15.8206 7.35526 15.8878C7.46388 15.9551 7.58789 15.9935 7.71551 15.9994C7.84314 16.0053 7.97016 15.9785 8.08452 15.9216L11.1689 14.3781C11.4179 14.2537 11.6273 14.0624 11.7736 13.8256C11.9199 13.5888 11.9973 13.3159 11.997 13.0375V9.77469C14.2655 8.32781 15.997 6.38719 15.997 3.17812C16.0001 2.39062 16.0001 1.60313 15.783 0.596875ZM12.0001 5.25C11.7529 5.25 11.5112 5.17669 11.3057 5.03934C11.1001 4.90199 10.9399 4.70676 10.8453 4.47835C10.7507 4.24995 10.7259 3.99861 10.7742 3.75614C10.8224 3.51366 10.9414 3.29093 11.1163 3.11612C11.2911 2.9413 11.5138 2.82225 11.7563 2.77402C11.9988 2.72579 12.2501 2.75054 12.4785 2.84515C12.7069 2.93976 12.9021 3.09998 13.0395 3.30554C13.1768 3.5111 13.2501 3.75277 13.2501 4C13.2501 4.33152 13.1185 4.64946 12.884 4.88388C12.6496 5.1183 12.3317 5.25 12.0001 5.25Z' fill='white'/%3E%3C/svg%3E%0A");
@svg-success: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8C16 12.4183 12.4183 16 8 16C3.58171 16 0 12.4183 0 8C0 3.58171 3.58171 0 8 0C12.4183 0 16 3.58171 16 8ZM7.07464 12.2359L13.0101 6.30045C13.2117 6.0989 13.2117 5.7721 13.0101 5.57055L12.2802 4.84064C12.0787 4.63906 11.7519 4.63906 11.5503 4.84064L6.70968 9.68123L4.44971 7.42126C4.24816 7.21971 3.92135 7.21971 3.71977 7.42126L2.98987 8.15116C2.78832 8.35271 2.78832 8.67952 2.98987 8.88106L6.34471 12.2359C6.54629 12.4375 6.87306 12.4375 7.07464 12.2359Z' fill='white'/%3E%3C/svg%3E%0A");
@svg-error: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M14.2857 0H1.71429C0.7675 0 0 0.7675 0 1.71429V14.2857C0 15.2325 0.7675 16 1.71429 16H14.2857C15.2325 16 16 15.2325 16 14.2857V1.71429C16 0.7675 15.2325 0 14.2857 0ZM6.86815 3.42871H9.13177C9.37763 3.42871 9.57309 3.63514 9.5597 3.88064L9.29477 8.73773C9.28238 8.96502 9.09445 9.14294 8.86685 9.14294H7.13308C6.90547 9.14294 6.71754 8.96502 6.70515 8.73773L6.44022 3.88064C6.42683 3.63514 6.62229 3.42871 6.86815 3.42871ZM6.35718 11.4285C6.35718 12.3359 7.09273 13.0714 8.00006 13.0714C8.9074 13.0714 9.64294 12.3359 9.64294 11.4285C9.64294 10.5212 8.9074 9.78564 8.00006 9.78564C7.09273 9.78564 6.35718 10.5212 6.35718 11.4285Z' fill='white'/%3E%3C/svg%3E%0A");
@svg-arrow-primary: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.72701 6.63395L2.46079 11.7355C2.0968 12.0882 1.50823 12.0882 1.14811 11.7355L0.272991 10.8878C-0.0909971 10.5352 -0.0909971 9.96499 0.272991 9.61613L4.00581 6L0.272991 2.38387C-0.0909971 2.03126 -0.0909971 1.46108 0.272991 1.11222L1.14811 0.264457C1.5121 -0.0881529 2.10068 -0.0881529 2.46079 0.264457L7.72701 5.36605C8.09099 5.71116 8.09099 6.28134 7.72701 6.63395Z' fill='%23228DFF'/%3E%3C/svg%3E%0A");
@svg-arrow-success: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.72701 6.63395L2.46079 11.7355C2.0968 12.0882 1.50823 12.0882 1.14811 11.7355L0.272991 10.8878C-0.0909971 10.5352 -0.0909971 9.96499 0.272991 9.61613L4.00581 6L0.272991 2.38387C-0.0909971 2.03126 -0.0909971 1.46108 0.272991 1.11222L1.14811 0.264457C1.5121 -0.0881529 2.10068 -0.0881529 2.46079 0.264457L7.72701 5.36605C8.09099 5.71116 8.09099 6.28134 7.72701 6.63395Z' fill='%2307BE07'/%3E%3C/svg%3E%0A");
@svg-arrow-danger: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.72701 6.63395L2.46079 11.7355C2.0968 12.0882 1.50823 12.0882 1.14811 11.7355L0.272991 10.8878C-0.0909971 10.5352 -0.0909971 9.96499 0.272991 9.61613L4.00581 6L0.272991 2.38387C-0.0909971 2.03126 -0.0909971 1.46108 0.272991 1.11222L1.14811 0.264457C1.5121 -0.0881529 2.10068 -0.0881529 2.46079 0.264457L7.72701 5.36605C8.09099 5.71116 8.09099 6.28134 7.72701 6.63395Z' fill='%23EC001D'/%3E%3C/svg%3E%0A");
@svg-arrow-warning: url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.72701 6.63395L2.46079 11.7355C2.0968 12.0882 1.50823 12.0882 1.14811 11.7355L0.272991 10.8878C-0.0909971 10.5352 -0.0909971 9.96499 0.272991 9.61613L4.00581 6L0.272991 2.38387C-0.0909971 2.03126 -0.0909971 1.46108 0.272991 1.11222L1.14811 0.264457C1.5121 -0.0881529 2.10068 -0.0881529 2.46079 0.264457L7.72701 5.36605C8.09099 5.71116 8.09099 6.28134 7.72701 6.63395Z' fill='%23FFD913'/%3E%3C/svg%3E%0A");

.alert {
  padding: @alert-padding;
  margin-bottom: @line-height-computed;
  border-radius: @border-radius;
  position: relative;
  line-height: 20px;
  box-shadow: @box-shadow;
  border: none;
  .overflow-break-anywhere();

  h4 {
    margin-top: 0;
    color: inherit;
  }

  > p,
  > ul {
    margin-bottom: 0;
  }

  > p + p {
    margin-top: 5px;
  }

  .alert-title {
    font-weight: 500;
    display: block;
    margin-bottom: 8px;
  }

  .match-header {
    margin: 8px 0px 0px 24px;

    &:has(> .fa-circle-info:first-child) {
      position: relative;
      margin-top: 0;

      & > .fa-circle-info:first-child {
        position: absolute;
        top: 3px;
        left: -24px;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &:not(.alert-no-icon) {
    border-left: 52px solid transparent;

    &:before {
      content: '';
      position: absolute;
      left: -34px;
      top: ~'calc(50% - 8px)';
      width: 16px;
      height: 16px;
    }
  }

  &.alert-no-icon {
    border-width: 1px;
    border-style: solid;
    box-shadow: none;

    & > svg:first-child {
      position: relative;
      top: 1px;
    }
  }

  &.alert-info {
    .alert-variant(@alert-info-bg; @alert-info-border; @text-color);

    &:not(.alert-no-icon) {
      border-color: @brand-primary;

      &:before {
        background-image: @svg-info;
      }
    }
  }

  &.alert-success {
    .alert-variant(@alert-success-bg; @alert-success-border; @text-color);

    &:not(.alert-no-icon) {
      border-color: @brand-success;

      &:before {
        background-image: @svg-success;
      }
    }
  }

  &.alert-danger {
    .alert-variant(@alert-danger-bg; @alert-danger-border; @text-color);

    &:not(.alert-no-icon) {
      border-color: @brand-danger;

      &:before {
        background-image: @svg-error;
      }
    }
  }

  &.alert-warning {
    .alert-variant(@alert-warning-bg; @alert-warning-border; @text-color);

    &:not(.alert-no-icon) {
      border-color: @brand-warning;

      &:before {
        background-image: @svg-error;
      }
    }
  }

  &.alert-dismissable {
    padding-right: 30px !important;

    .close {
      position: absolute;
      top: calc(50% - 11px);
      right: 14px;
    }
  }

  &.alert-collapsible {
    padding: 16px 18px;

    & > h2 {
      & > .btn {
        padding: 5px;
        margin: -5px;
      }
    }

    &.alert-info > h2:hover > .btn,
    &.alert-info > h2 > .btn:where(:hover, :focus) {
      color: @secondary-600;
    }

    &.alert-warning > h2:hover > .btn,
    &.alert-warning > h2 > .btn:where(:hover, :focus) {
      color: @warning-600;
    }

    .alert-collapsible-body {
      margin: 16px 0 4px 26px;
    }

    p {
      font-size: 14px;
      line-height: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3 {
      color: @neutral-900;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin: 12px 0 8px;
    }
  }

  &.row-alert {
    margin-bottom: @box-margin;

    h2 {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      margin-top: 0;
    }

    p:not(:last-child),
    ul:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
