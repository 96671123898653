.user {
  .dropdown-menu {
    a {
      padding: 8px;
      border-radius: 4px;
      color: @neutral-800;

      &:hover,
      &:focus {
        background: @neutral-100;
      }
    }
  }
}
