.schedule-form {
  .Select.schedule-type-select,
  .Select.schedule-table-select {
    .react-select__control {
      box-shadow: none !important;
      border: 1px solid @neutral-400 !important;
      background-color: @white !important;
    }

    .react-select__single-value {
      color: @neutral-400 !important;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: @letter-spacing-wider;
    }

    &.type-select {
      width: 175px;

      .react-select__control {
        border-right: none !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.fill-space .react-select__control {
        border-right: 1px solid @neutral-400 !important;
        border-top-right-radius: @border-radius;
        border-bottom-right-radius: @border-radius;
      }
    }
  }

  .input-period {
    .input-group-addon:first-child {
      padding: 10px;
      border-top: 1px solid @neutral-400;
      border-left: 1px solid @neutral-400;
      border-bottom: 1px solid @neutral-400;
      border-top-left-radius: @border-radius;
      border-bottom-left-radius: @border-radius;
    }
    .form-control {
      background-color: @white;
      border: 1px solid @neutral-400;
      border-left: none;
      border-right: none;

      &:focus {
        border: 1px solid @neutral-400;
        box-shadow: none;
      }
    }
    .input-group-addon:last-child {
      border-top: 1px solid @neutral-400;
      border-right: 1px solid @neutral-400;
      border-bottom: 1px solid @neutral-400;
      border-top-right-radius: @border-radius;
      border-bottom-right-radius: @border-radius;
    }
  }

  .input-group {
    .form-control[type='number'] {
      font-size: 12px;
      font-weight: bold;
      text-align: right;
      letter-spacing: @letter-spacing-wider;
    }
  }

  .Select + .flex-container {
    margin-left: 8px;
  }

  .input-group-addon {
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
    color: @neutral-400;
    background-color: @white;
    text-transform: uppercase;
    letter-spacing: @letter-spacing-wider;
    padding: 13px 10px 11px 0px;
  }

  .Select .fa-clock,
  .input-group-addon .fa-circle-question {
    color: @neutral-400;
    font-size: 16px;
    margin-right: 8px;
    position: relative;
    top: 2px;
  }
}

.cron-preview {
  margin: 13px 0px 10px 0px;
  padding: 17px 20px;
  border-radius: @border-radius;
  border: 1px solid @neutral-400;
}

.schedule-predefined {
  .predefined-button {
    line-height: 14px;
    padding: 5px 8px;
    margin-bottom: 6px;
  }
}
