.animation-bar-box {
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 6px;

  .animation-bar {
    position: relative;
    display: block;
    height: 14px;
    border-radius: 2px;
    background: linear-gradient(to right, @neutral-150 46%, @neutral-100 50%, @neutral-150 54%);
    animation: gradient 1600ms ease-in-out infinite;
    background-size: 200%;

    & + .animation-bar::after {
      content: '';
      display: inline-block;
      width: 80px;
      height: 14px;
      background-color: @neutral-50;
      position: absolute;
      right: 0;
      top: 0px;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 110% 50%;
  }
  100% {
    background-position: -10% 50%;
  }
}
