.sidebar-content {
  .sidebar-box {
    padding: 0 23px;
    margin-bottom: @box-margin;

    h4 {
      margin-bottom: 17px;
    }
  }

  .created-by > div {
    margin-bottom: 7px;
    font-size: 13px;
  }

  .job-row,
  .job-link,
  .version-row,
  .version-link {
    display: block;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;

    &.highlight {
      position: relative;

      @keyframes highlight {
        0%,
        100% {
          background-color: transparent;
        }
        10%,
        90% {
          background-color: rgba(224, 240, 255, 0.8);
        }
      }

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -4px;
        right: -10px;
        bottom: -4px;
        left: -10px;
        z-index: -1;
        animation: highlight 3s linear 1;
        border-radius: @border-radius;
      }
    }

    .time-info,
    .status-info {
      position: relative;
      top: 4px;
      font-size: 16px;
    }

    .job-duration {
      margin-left: 4px;
    }

    &.jobs-page,
    &.versions-page {
      line-height: 24px;
      margin-top: 16px;
    }

    .version-label {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      margin-right: 6px;
    }
  }

  .compare-latest {
    font-size: 14px;
    font-weight: 400;
    text-align: right;

    .svg-inline--fa {
      color: @neutral-400;
    }
  }

  .nav.nav-stacked {
    background: rgba(240, 243, 245, 0.6);
    border: @border;
    box-sizing: border-box;
    box-shadow: inset 0 2px 4px rgba(196, 213, 228, 0.2);
    border-radius: @border-radius-large;
    padding: 16px;
    margin-bottom: 26px;
    container-type: inline-size;

    li {
      margin: 0;

      & + li:not(:empty) {
        margin-top: 4px;
      }

      & + li.additionalPrimary {
        margin-top: 16px;
      }

      &.removeDivider hr {
        display: none;
      }
    }

    @container (max-width: 210px) {
      .btn-success,
      .btn-default {
        padding-left: 8px;
        padding-right: 8px;

        svg {
          display: none;
        }
      }
    }

    a:not(.btn-default),
    .btn-link {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      white-space: pre-wrap;
      width: 100%;
      min-height: 36px;
      padding: 8px 8px 8px 36px;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      color: @neutral-800;
      border-radius: 4px;
      letter-spacing: normal;
      text-align: left;
      text-decoration: none;
      text-transform: none;
      cursor: pointer;

      &:hover,
      &:focus {
        color: @neutral-800;
        background-color: @neutral-150;

        & > *:first-child {
          color: @secondary-500-base;
        }
      }

      & > *:first-child {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto 0;
        color: @neutral-400;

        &.switch {
          left: 5px;
        }
      }

      &:not(:first-child) {
        margin-top: 4px;
      }

      & svg {
        width: 16px;
        height: 16px;

        &.fa-clock,
        &.fa-wrench,
        &.fa-circle-play,
        &.fa-circle-check,
        &.fa-circle-pause,
        &.fa-circle-question {
          width: 18px;
          height: 18px;
        }
      }
    }

    a.disabled,
    .btn.disabled,
    button.disabled,
    button[disabled] {
      cursor: not-allowed;
      color: @neutral-400 !important;

      &.btn-success {
        color: @white;
      }
    }

    hr {
      margin: 16px 0;
    }

    li:has(> hr:last-child) + li:has(> hr:first-child) hr {
      display: none;
    }
  }

  hr {
    margin: 26px 0 28px 0;
  }
}
