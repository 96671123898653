.empty-jobs-graph {
  position: relative;
  margin: 0px 28px @box-margin 28px;

  .empty-jobs-graph-image {
    max-height: 240px;
    min-height: 169px;
    margin: 0 auto;
  }

  .empty-jobs-graph-text {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    min-width: 250px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
