.job-mapping-panel,
.job-parameters-results-panel {
  min-height: 240px;
  padding: 0 10px 10px 10px;

  .box-header {
    min-height: 65px;
    padding: 10px 10px 0px 22px;
  }

  .box-panel-content {
    flex: 1;
    min-height: 100px;
    padding: 0px 22px;
    overflow: hidden;
    position: relative;

    &:after {
      content: '';
      background-image: linear-gradient(to bottom, @transparent, white);
      bottom: 0;
      height: 20px;
      position: absolute;
      width: 100%;
      pointer-events: none;
    }
  }
}

.job-graph-header {
  padding-left: 28px;
  margin-top: 0;
  margin-bottom: 17px;
}

.job-total-duration-panel,
.job-error-panel {
  padding: 0px 12px 24px 16px;
}
