.job-status-label {
  position: relative;
  font-weight: 500;

  .status-icon {
    font-size: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
    color: @warning-500-base;
    background: @warning-100;
    border-radius: @border-round;
    border: 2px solid @white;
  }
}
