.overlay-opacity-wrapper {
  position: relative;

  > *:not(.overlay-opacity-content) {
    opacity: 0.25;
  }

  .overlay-opacity-content {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    text-align: center;

    & > div {
      max-width: 400px;
      margin: auto;
    }
  }
}
