.dropdown-menu {
  min-width: 200px;
  border-radius: 6px;
  border: 1px solid rgba(162, 165, 169, 0.15);
  box-shadow: 0px 10px 20px @box-shadow-color;
  cursor: initial;

  &.wide {
    min-width: 240px;
  }

  .divider {
    background-color: @neutral-100;
  }
}

.row-dropdown {
  .dropdown-toggle {
    width: 40px;
    height: 42px;
    padding: 10px 8px;

    &.in-navigation {
      height: 40px;
    }

    svg path {
      fill: @neutral-400;
      transition: fill 200ms ease-in-out;
    }

    &:hover svg path,
    &:focus svg path {
      fill: @secondary-500-base;
    }

    &:focus {
      .tab-focus();
    }

    .fa-ellipsis {
      font-size: 22px !important;
    }
  }

  .dropdown-menu {
    z-index: 11;
    padding: 7px 8px;
    min-width: 260px;

    hr {
      width: initial;
      margin: 4px 8px;
    }

    [role='menuitem'] {
      cursor: pointer;
      position: relative;
      width: 100%;
      display: block;
      padding: 8px 8px 8px 36px;
      clear: both;
      font-weight: 400;
      line-height: 20px;
      color: @neutral-800;
      border-radius: 4px;
      text-decoration: none;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.4;
      }

      &:hover:not(.disabled),
      &:focus:not(.disabled) {
        background: @neutral-100;

        & > .svg-inline--fa,
        & > span:not(.fa-layers) > .svg-inline--fa,
        & > .fa-layers > .svg-inline--fa:first-child {
          color: @secondary-500-base;
        }
      }

      & > :is(.fa-layers, .svg-inline--fa),
      & > span:not(.fa-layers) > .svg-inline--fa {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
        margin: auto 0;
        color: @neutral-400;
        width: 16px;
        height: 16px;

        &.fa-toggle-large-on {
          color: @primary-500-base !important;
        }

        &.fa-toggle-large-off {
          color: @neutral-400 !important;
        }

        &.bigger {
          left: 9px;
          width: 18px;
          height: 18px;
        }
      }
    }

    [role='presentation']:first-of-type:has(
        + [role='separator'] + [role='presentation']:not(:last-of-type)
      )
      > [role='menuitem'] {
      & > :is(.fa-layers, .svg-inline--fa),
      & > span:not(.fa-layers) > .svg-inline--fa {
        color: @primary-500-base !important;
      }
    }
  }
}
