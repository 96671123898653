.graph {
  .node {
    cursor: pointer;

    rect {
      stroke-width: 0px;
      stroke: @neutral-700;
      fill: @white;
    }

    text {
      background-color: @neutral-300;
    }

    text,
    a {
      display: inline-block;
      padding: 2px 4px;
      font-size: 12px;
      font-weight: bold;
      line-height: 14px;
      color: @white;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
  }
}
