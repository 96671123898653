.safari-browser {
  // safari do not support relative position on table-row
  .tr.divide-row {
    display: block !important;

    &::after {
      display: none !important;
    }
  }

  // safari do not support box-shadow on table-row
  table > thead > tr > th,
  .table > .thead > .tr > .th {
    border-bottom: 1px solid @neutral-150 !important;
  }
}
