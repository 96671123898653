.provisioning-credentials-modal {
  .modal-body {
    padding: 25px 12px 15px 12px;

    & > a,
    & > button {
      display: flex;
      align-items: flex-start;
      padding: 5px 0;
      color: inherit;
      border: none;

      & > div {
        padding: 5px 10px;
        flex-grow: 2;

        p {
          font-size: 12px;
        }
      }

      .fa-chevron-right {
        align-self: center;
      }
    }
  }
}
