.info-alert-section {
  margin-top: 22px;

  .alert {
    margin-bottom: -7px;

    .close {
      right: 12px;
      top: calc(50% - 8px);
      opacity: 1;
      color: @neutral-400;

      &:hover,
      &:focus {
        color: @neutral-500-base;
      }

      .fa-xmark {
        font-size: 18px;
      }
    }
  }
}
