.searchbar {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-bottom: 1em;

  .searchbar-form {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
  }

  .searchbar-input {
    font-size: 16px;
    line-height: 14px;
    color: @text-color;
    background-color: @white;
    padding: 11px 40px 11px 34px;
    width: 100%;

    &::placeholder {
      color: @text-color-gray;
    }
  }

  &.with-border .searchbar-input {
    border: 1px solid @border-color;
  }

  &.as-input .searchbar-input {
    font-size: 14px;
    background-color: @input-bg;
  }

  &.condensed .searchbar-input {
    font-size: 14px;
  }

  .searchbar-actions {
    display: flex;
    flex-flow: row;
    align-items: center;

    & > *:not(:empty) {
      margin-left: 8px;
    }
  }

  .searchbar-clear-btn {
    display: flex;
    position: absolute;
    right: 0px;

    &:hover,
    &:focus {
      text-decoration: none;

      .fa-xmark {
        color: inherit;
      }
    }

    .fa-xmark {
      color: @neutral-300;
      width: 17px;
      height: 17px;
    }
  }

  .fa-magnifying-glass {
    position: absolute;
    left: 10px;
    color: @text-color-gray;
    width: 16px;
    height: 16px;
  }
}
