.sort-icon {
  color: @neutral-400;

  &.fa-sort .fa-secondary {
    opacity: 1;
  }

  &.fa-sort-up .fa-primary,
  &.fa-sort-down .fa-primary {
    color: @neutral-900;
  }
}
