.floating-notifications {
  transform: translateX(-50%);

  .notification {
    box-shadow: @box-shadow-secondary;

    + .notification {
      margin-top: 8px;
    }

    a {
      color: @white;
    }

    .notification-action {
      color: @white;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 6px 12px;
      margin: 0 12px 2px 0;

      &:hover,
      &:focus {
        background-color: rgba(255, 255, 255, 0.1);
        border-color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
      }
    }

    .notification-close {
      color: @white;
      border-left: 1px solid rgba(255, 255, 255, 0.5);

      &:hover,
      &:focus {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .notification-progress {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .notify {
    &-enter {
      opacity: 0;
      transform: scale(0.8) translateY(50px);

      &-active {
        opacity: 1;
        transform: scale(1) translateY(0px);
        transition:
          opacity 300ms linear,
          transform 300ms cubic-bezier(0, 0.5, 0.5, 1);
      }
    }

    &-exit {
      opacity: 1;

      &-active {
        opacity: 0;
        transition: opacity 300ms ease-in;
      }
    }
  }
}

@keyframes notification-progress {
  100% {
    width: 0%;
  }
}
