body.modal-open .tooltip.hide-when-modal-open {
  z-index: 1039; // one smaller than modal backdrop
}

.tooltip {
  line-height: 20px;
  z-index: 1051; // one bigger than modal

  &.action {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.06rem;

    .tooltip-inner {
      white-space: nowrap;
      max-width: fit-content;
    }
  }

  &.explanatory {
    font-size: 14px;
    text-align: left;

    .tooltip-inner {
      padding: 12px 16px;
      max-width: 340px;
    }
  }

  .tooltip-arrow {
    display: none;
  }

  --tooltip-background-color: @neutral-800;

  &.tooltip-danger {
    --tooltip-background-color: @error-500-base;
  }

  .tooltip-inner {
    background-color: var(--tooltip-background-color);
    padding: 8px 12px;
    text-align: inherit;
  }

  &.in {
    filter: none;
    opacity: 1;
  }

  &.widest .tooltip-inner {
    max-width: 450px;
  }

  hr {
    margin: 12px 0;
    border-color: @neutral-600;
  }

  a,
  a:is(:hover, :focus) {
    color: inherit;
  }

  :last-child {
    margin-bottom: 0;
  }
}

.tooltip-title {
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
  color: @neutral-300;
  text-transform: uppercase;
}

.tooltip-danger .tooltip-title {
  color: @white;
}

span.run-id-tooltip > button {
  &,
  svg {
    margin-left: 4px;
  }

  svg {
    bottom: 2px !important;
  }
}
