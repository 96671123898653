.project-settings-page {
  // left side
  .row {
    line-height: 60px;
    white-space: nowrap;
    vertical-align: middle;

    &.odd {
      background-color: @neutral-50;
    }
  }

  // right side
  .panel {
    margin: 0 2em 2em 0;
    padding: 1em 0;

    .panel-heading {
      padding: 15px 0;
      background-color: @white;
      line-height: 30px;
      font-size: 16px;
      margin: 0 auto;
      border-bottom: none;

      h3 {
        margin: 0 0 0 30px;
      }
    }
  }
}
