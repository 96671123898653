.git-sync-page {
  .terminal-with-kbc-init {
    margin-bottom: 1em;
    border-radius: @border-radius;
    padding: 1em;
    max-width: 50em;
    color: @white;
    background-color: @neutral-800;
    font-size: 13px;
    font-family: @font-family-monospace;
  }
}
