.w-50 {
  width: 50px;
}

.w-52 {
  width: 52px;
}

.w-100 {
  width: 100px;
}

.w-125 {
  width: 125px;
}

.w-150 {
  width: 150px;
}

.w-175 {
  width: 175px;
}

.w-200 {
  width: 200px;
}

.w-250 {
  width: 250px;
}

.w-280 {
  width: 280px;
}

.w-300 {
  width: 300px;
}

.w-400 {
  width: 400px;
}

.w-500 {
  width: 500px;
}

.w-600 {
  width: 600px;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-400 {
  max-width: 400px;
}

.max-w-500 {
  max-width: 500px;
}

.max-w-700 {
  max-width: 700px;
}

.font-sans {
  font-family: @font-family-sans-serif;
}

.font-mono {
  font-family: @font-family-monospace;
}

.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.line-through {
  text-decoration: line-through !important;
}

.color-primary {
  color: @secondary-500-base !important;

  &.hoverable {
    &:hover,
    &:focus,
    &:active {
      color: @link-hover-color !important;
    }
  }
}

.color-success {
  color: @primary-500-base !important;

  &.hoverable {
    &:hover,
    &:focus,
    &:active {
      color: @primary-700 !important;
    }
  }
}

.color-success-muted {
  color: @primary-600;
}

.color-danger {
  color: @error-500-base !important;

  &.hoverable {
    &:hover,
    &:focus,
    &:active {
      color: @error-700 !important;
    }
  }
}

.color-base {
  color: @neutral-500-base !important;
}

.color-muted {
  color: @neutral-400 !important;

  &.hoverable {
    &:hover,
    &:focus,
    &:active {
      color: @secondary-500-base !important;
    }
  }
}

.color-warning {
  color: @warning-500-base !important;
}

.color-dark {
  color: @neutral-900 !important;
}

.color-dark-muted {
  color: @neutral-600;
}

.color-orange {
  color: @warning-500-base !important;
}

.color-gray {
  color: @neutral-100 !important;
}

.color-cyan {
  color: @accent-cyan-500-base !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-36 {
  font-size: 36px !important;
}

.bt {
  border-top: @border;
}

.bb {
  border-bottom: @border;
}

.bl {
  border-left: @border;
}

.no-border {
  border: none;
}

.border-danger {
  border: solid 2px @error-500-base;
}

.pointer-events-none {
  pointer-events: none;
}

.user-select-none {
  user-select: none !important;
}

.clickable {
  cursor: pointer;
}

.dragable {
  cursor: grab;
}

.not-allowed {
  cursor: not-allowed !important;
}

.cursor-help {
  cursor: help;
}

.font-size-inherit {
  font-size: inherit !important;
}

.color-inherit {
  color: inherit !important;
}

.link-inherit {
  color: inherit !important;

  &:hover,
  &:focus,
  &:active {
    color: @secondary-500-base !important;
  }
}

.color-purple {
  color: @accent-purple-500-base !important;
}

.no-bg {
  background: none;
}

.bg-color {
  background: @neutral-50 !important;
}

.bg-color-purple {
  background-color: @accent-purple-500-base !important;
}

.bg-color-orange {
  background-color: @warning-500-base !important;
}

.bg-color-gray {
  background-color: @neutral-100 !important;
}

.bg-color-input {
  background-color: @neutral-50;
}

.bg-color-white {
  background-color: @white !important;
}

.bg-color-muted-light {
  background-color: @neutral-400 !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.underline {
  text-decoration: underline !important;

  &.clickable:hover {
    text-decoration: none !important;
  }
}

.dashed-underline {
  border-bottom: 1px dashed @neutral-400;

  &:hover {
    border-bottom-color: @transparent;
  }
}

.border-color-inherit {
  border-color: inherit;
}

.dotted-underline {
  border-bottom: 1px dotted @secondary-400;
}

a.dotted-underline {
  &:hover,
  &:focus,
  &:active {
    border-color: @secondary-500-base;
    text-decoration: none;
  }
}

.uppercase {
  text-transform: uppercase !important;
}

.circle {
  border-radius: @border-round;
}

.no-underline,
.no-underline:hover {
  text-decoration: none !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.pre-wrap {
  white-space: pre-wrap !important;
}

.pre {
  white-space: pre !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-break-anywhere {
  word-break: break-all;
  word-break: break-word;
  overflow-wrap: anywhere;
}

.break-all {
  word-break: break-all !important;
}

.fade-transition(@property: all, @time: 250ms) {
  transition: @property @time ease-in;
}

.box-separator {
  margin-top: @box-margin;
}

.vertical-middle {
  vertical-align: middle !important;
}

.vertical-top {
  vertical-align: top !important;
}

hr.inside-modal-body {
  margin-left: -@padding-base*3;
  margin-right: -@padding-base*3;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.md-mr-auto {
  @media all and (max-width: @screen-md-min) {
    .mr-auto();
  }
}

.mt-6 {
  margin-top: 6em;
}

.pl-2x-base {
  padding-left: @font-size-base * 2;
}

.color-main {
  color: @neutral-800 !important;
}

.text-muted,
.btn.text-muted,
.text-muted > .btn-link-inline:not(.color-primary) {
  color: @neutral-400;

  &.btn:is(:hover, :focus):not(.disable):not(.disabled):not(.btn-disabled) {
    color: @secondary-500-base;
  }
}

.text-muted-light {
  color: @neutral-200 !important;

  &.btn:hover:not(.disable):not(.disabled),
  &.btn:focus:not(.disable):not(.disabled) {
    color: @secondary-400 !important;
  }
}

.shared-to-label {
  height: 28px;
  border-radius: 14px;
  line-height: 28px;
  font-size: 11px;
  background-color: @neutral-400;
  color: @white;
  display: inline-block;
  padding: 0 1em;
  white-space: nowrap;
}

.value-list {
  &.disabled {
    opacity: 0.2;
  }

  & > *:not(:last-of-type) {
    margin-bottom: 10px;
  }

  .btn[data-clipboard-text] {
    text-align: right;
  }
}

ul.data-list {
  padding-left: 20px;
  list-style: circle;
  overflow-wrap: anywhere;
  margin-bottom: 0;
}

.tables-list {
  display: flex;
  flex-direction: column;

  & > span + span {
    margin-top: 4px;
  }
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-28 {
  line-height: 28px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-40 {
  line-height: 40px;
}

.letter-spacing-wider {
  letter-spacing: @letter-spacing-wider;
}

.letter-spacing-narrower {
  letter-spacing: @letter-spacing-narrower;
}

.display-block {
  display: block !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-none {
  display: none !important;
}

.opacity-half {
  opacity: 0.5 !important;
}

.truncated {
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;

  &-2-lines {
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
}

.is-sticky {
  position: sticky;
  top: 66px;
  z-index: 10;

  .sticky-position-zero & {
    top: 0;
  }

  &:has(> .directory-filter) {
    top: 10px;

    @media all and (max-height: @min-height-for-sticky-table) {
      position: relative;
    }
  }
}

.title-hint {
  margin-left: 6px;
  color: @neutral-400;
  cursor: pointer;
}

.icon-addon-right {
  margin-right: 10px;
}

.icon-addon-left {
  margin-left: 10px;
}

.color-source {
  color: @secondary-600;
}

.color-destination {
  color: @accent-teal-600;
}

.color-application {
  color: @accent-purple-600;
}

.color-transformation {
  color: @accent-cyan-600;
}
